var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-expansion-panel-header',[_vm._v("Centerline "),(_vm.centerlineComputed == true)?[_c('v-icon',[_vm._v("mdi-check")])]:_vm._e()],2),_c('v-expansion-panel-content',[_c('p',[_vm._v(" Place first the inlet seed and then outlet seeds. Finally click the run button to compute and render centerline ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"8"}},[_c('v-switch',{attrs:{"dense":"","inset":"","label":"Place inlet and outlet seeds","color":"primary","hide-details":"","disabled":_vm.loading,"id":"centerline_seeds"},on:{"change":_vm.togglePicking},model:{value:(_vm.seeds),callback:function ($$v) {_vm.seeds=$$v},expression:"seeds"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"switch-aligned mr-5",attrs:{"fab":"","dark":"","x-small":"","disabled":_vm.loading},on:{"click":_vm.toggleSeeds}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)]}}])},[_c('span',[_vm._v("Show/Hide seeds")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"switch-aligned mr-5",attrs:{"fab":"","dark":"","x-small":"","disabled":_vm.loading},on:{"click":_vm.removeLastSeed}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-restore")])],1)]}}])},[_c('span',[_vm._v("Delete last seed")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"switch-aligned mr-5",attrs:{"fab":"","dark":"","x-small":"","disabled":_vm.loading},on:{"click":_vm.removeSeeds}},on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-delete")])],1)]}}])},[_c('span',[_vm._v("Delete all seeds")])])],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"switch-aligned",attrs:{"fab":"","dark":"","x-small":"","disabled":_vm.loading || _vm.notReady},on:{"click":_vm.computeCenterline}},on),[_c('v-icon',{attrs:{"dark":"","color":"red"}},[_vm._v("mdi-cogs")])],1)]}}])},[_c('span',[_vm._v("Compute Centerline")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }