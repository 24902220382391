import store from "@/store/index";
import { map, find, cloneDeep, omit, startCase, pick, assign } from "lodash";
import { dataManager } from "@/common/api.dataset";

const colors = ["#c3d451", "#3f4a84", "#419774"];

let defaultConfig = {
  responsive: true,
  scrollZoom: true,
  modeBarButtonsToRemove: ["hoverCompareCartesian", "zoomIn2d", "zoomOut2d"]
};

let defaultScatterLayout = {
  autosize: true,
  hovermode: "closest",
  margin: {
    l: 75,
    r: 50,
    b: 75,
    t: 50,
    pad: 0
  },
  // "dark mode" as vue background
  plot_bgcolor: "#121212",
  paper_bgcolor: "#121212",
  xaxis: {
    title: {
      text: "XAXIS",
      standoff: 20
    },
    color: "#888888",
    visible: true,
    automargin: true,
    showline: true,
    zeroline: false,
    tickfont: {
      size: 12
    }
  },
  yaxis: {
    title: {
      text: "YAXIS",
      standoff: 10
    },
    color: "#888888",
    visible: true,
    automargin: true,
    showline: false,
    zeroline: false
  },
  legend: {
    x: 0.25,
    y: 1.1,
    font: {
      family: "sans-serif",
      size: 14,
      color: "#888888"
    },
    orientation: "h"
  }
};

let defaultHistLayout = {
  autosize: true,
  hovermode: "closest",
  margin: {
    l: 75,
    r: 50,
    b: 75,
    t: 50,
    pad: 0
  },
  // "dark mode" as vue background
  plot_bgcolor: "#121212",
  paper_bgcolor: "#121212",
  xaxis: {
    title: {
      text: "XAXIS",
      standoff: 20
    },
    color: "#888888",
    visible: true,
    automargin: true,
    showline: true,
    zeroline: false,
    tickfont: {
      size: 12
    }
  },
  yaxis: {
    title: {
      text: "YAXIS",
      standoff: 10
    },
    color: "#888888",
    visible: true,
    automargin: true,
    showline: false,
    zeroline: false
  },
  legend: {
    x: 0.25,
    y: 1.1,
    font: {
      family: "sans-serif",
      size: 14,
      color: "#888888"
    },
    orientation: "h"
  },
  bargap: 0.05,
  bargroupgap: 0.05,
  barmode: "overlay"
};

let defaultRadarLayout = {
  autosize: true,
  hovermode: "closest",
  margin: {
    l: 75,
    r: 50,
    b: 75,
    t: 50,
    pad: 0
  },
  // "dark mode" as vue background
  plot_bgcolor: "#121212",
  paper_bgcolor: "#121212",
  legend: {
    x: 0.25,
    y: 1.1,
    font: {
      family: "sans-serif",
      size: 14,
      color: "#888888"
    },
    orientation: "h"
  },
  polar: {
    bgcolor: "#121212",
    radialaxis: {
      visible: true,
      rangemode: "normal"
    }
  }
};

export const generateFakeScatter = function(divId) {
  let data = {
    x: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => Math.random() * i),
    // y: [1, 2, 4, 8, 16, 32, 64, 128, 256, 512].map(i => Math.random() * i),
    y: [10, 10, 10, 10, 10, 10, 10, 10, 10, 10].map(i => Math.random() * i),
    mode: "markers",
    type: "scatter"
  };

  /* eslint-disable-next-line no-undef */
  Plotly.newPlot(divId, [data], defaultScatterLayout, defaultConfig);
};

export const generateFakeHist = function(divId) {
  let data = {
    x: new Array(100).fill(10).map(i => Math.random() * i),
    type: "histogram",
    autobinx: false,
    opacity: 0.6,
    xbins: {
      size: 0.5,
      start: 0,
      end: 10
    }
  };

  /* eslint-disable-next-line no-undef */
  Plotly.newPlot(divId, [data], defaultHistLayout, defaultConfig);
};

export const generateFakeRadar = function(divId) {
  let data = {
    r: [3, 3, 3, 3, 3].map(i => 2 + Math.random() * i),
    theta: ["A", "B", "C", "D", "E"],
    type: "scatterpolar",
    fill: "toself"
  };

  // close the plot
  data.r.push(data.r[0]);
  data.theta.push(data.theta[0]);

  /* eslint-disable-next-line no-undef */
  Plotly.newPlot(divId, [data], defaultRadarLayout, defaultConfig);
};

const renderScatterPlot = function(divId, data, customProps) {
  let traces = [];

  let chartLayout = cloneDeep(defaultScatterLayout);
  assign(chartLayout, customProps);

  if (data.length > 0) {
    traces = map(data, (subset, i) => {
      let currentProps = find(store.state.dataAnalysis.currentSeries, [
        "key",
        subset.key
      ]);

      let size = 8;
      let color = colors[i];
      if (currentProps && currentProps.marker) {
        size = currentProps.marker.size;
        color = currentProps.marker.color;
      }

      return {
        key: subset.key,
        x: subset.x,
        y: subset.y,
        name: startCase(subset.key),
        mode: "markers",
        type: "scatter",
        marker: {
          size: size,
          color: color
        }
      };
    });

    // if string data type set axis layout consequently
    if (typeof traces[0].x[0] == "string") {
      chartLayout.xaxis.type = "category";
      chartLayout.xaxis.tickangle = 45;
    }
    if (typeof traces[0].y[0] == "string") {
      chartLayout.yaxis.type = "category";
      chartLayout.yaxis.tickangle = 45;
    }

    // add axis names
    chartLayout.xaxis.title.text = startCase(
      store.state.dataAnalysis.activeKeyX
    );
    chartLayout.yaxis.title.text = startCase(
      store.state.dataAnalysis.activeKeyY
    );
  }

  /* eslint-disable-next-line no-undef */
  Plotly.newPlot(divId, traces, chartLayout, defaultConfig);

  return traces;
};

const renderHistPlot = function(divId, data, customProps) {
  let traces = [];

  let chartLayout = cloneDeep(defaultHistLayout);
  assign(chartLayout, customProps);

  if (data.length > 0) {
    traces = map(data, (subset, i) => {
      let currentProps = find(store.state.dataAnalysis.currentSeries, [
        "key",
        subset.key
      ]);

      let color = colors[i];
      if (currentProps && currentProps.marker) {
        color = currentProps.marker.color;
      }

      return {
        key: subset.key,
        x: subset.x,
        name: startCase(subset.key),
        type: "histogram",
        autobinx: true,
        opacity: 0.6,
        marker: {
          color: color
        }
      };
    });

    // add axis names
    chartLayout.xaxis.title.text = startCase(
      store.state.dataAnalysis.activeKeyX
    );
    chartLayout.yaxis.title.text = "Number of entries";
  }

  /* eslint-disable-next-line no-undef */
  Plotly.newPlot(divId, traces, chartLayout, defaultConfig);

  return traces;
};

const renderRadarPlot = function(divId, data, customProps) {
  let traces = [];

  let chartLayout = cloneDeep(defaultRadarLayout);
  assign(chartLayout, customProps);

  if (data.length > 0) {
    traces = map(data, (subset, i) => {
      // close the plot
      subset.r.push(subset.r[0]);
      subset.theta.push(subset.theta[0]);

      let currentProps = find(store.state.dataAnalysis.currentSeries, [
        "key",
        subset.key
      ]);

      let size = 8;
      let color = colors[i];
      if (currentProps && currentProps.marker) {
        color = currentProps.marker.color;
      }

      return {
        key: subset.key,
        r: subset.r,
        theta: subset.theta,
        name: startCase(subset.key),
        type: "scatterpolar",
        fill: "toself",
        opacity: 0.6,
        marker: {
          color: color,
          size: size
        }
      };
    });

    // if string data type set axis layout consequently
    if (typeof traces[0].r[0] == "string") {
      chartLayout.polar.radialaxis.type = "category";
    }
  }

  /* eslint-disable-next-line no-undef */
  Plotly.newPlot(divId, traces, chartLayout, defaultConfig);

  return traces;
};

const formatData = function(data, chartType) {
  let parsedData = {};
  let xKey, yKey;
  switch (chartType) {
    case "scatterChart":
      xKey = store.state.dataAnalysis.activeKeyX;
      yKey = store.state.dataAnalysis.activeKeyY;

      parsedData = map(data, (d, k) => {
        return {
          x: map(d, xKey),
          y: map(d, yKey),
          key: k
        };
      });
      break;
    case "histChart":
      xKey = store.state.dataAnalysis.activeKeyX;
      parsedData = map(data, (d, k) => {
        return {
          x: map(d, xKey),
          key: k
        };
      });

      break;
    case "radarChart":
      xKey = store.state.dataAnalysis.activeKeyX;
      yKey = store.state.dataAnalysis.activeKeyY;

      parsedData = map(yKey, caseName => {
        let caseObj = find(data, ["key", caseName]);
        let subObj = pick(caseObj, xKey);
        return {
          r: Object.values(subObj),
          theta: Object.keys(subObj),
          key: caseName
        };
      });

      break;
  }
  return parsedData;
};

export const updateChart = function(divId, chartType) {
  let renderLiteratureData = store.state.dataAnalysis.showLiteratureData;
  let data = dataManager;

  let formattedData = {};
  let renderedSeries = [];
  switch (chartType) {
    case "scatterChart":
      // generateFakeScatter(divId);
      if (!renderLiteratureData) {
        data = omit(data, "literature_data");
      }
      formattedData = formatData(data, chartType);
      renderedSeries = renderScatterPlot(divId, formattedData);
      break;
    case "histChart":
      // generateFakeHist(divId);
      if (!renderLiteratureData) {
        data = omit(data, "literature_data");
      }
      formattedData = formatData(data, chartType);
      renderedSeries = renderHistPlot(divId, formattedData);
      break;
    case "radarChart":
      // generateFakeRadar(divId);
      var allData = dataManager.user_data;
      if (renderLiteratureData) {
        allData = allData.concat(dataManager.literature_data);
      }
      formattedData = formatData(allData, chartType);
      renderedSeries = renderRadarPlot(divId, formattedData);
      break;
  }

  store.dispatch("dataAnalysis/setCurrentSeries", renderedSeries);
};
