import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import Dashboard from "@/views/Dashboard.vue";
import Viewer from "@/views/Viewer.vue";
import DataAnalysis from "@/views/DataAnalysis.vue";
import Header from "@/components/Header.vue";
import HeaderViewer from "@/components/HeaderViewer.vue";
import SidePanel from "@/components/SidePanel.vue";
import DataPanel from "@/components/DataPanel.vue";

Vue.use(VueRouter);

window.popStateDetected = false;
window.addEventListener("popstate", () => {
  window.popStateDetected = true;
});

const routes = [
  {
    path: "/",
    name: "home",
    components: {
      content: Home
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  },
  {
    path: "/dashboard",
    name: "dashboard",
    components: {
      header: Header,
      content: Dashboard
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  },
  {
    path: "/viewer",
    name: "viewer",
    components: {
      header: HeaderViewer,
      content: Viewer,
      sidePanel: SidePanel
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  },
  {
    path: "/dataAnalysis",
    name: "dataAnalysis",
    components: {
      header: Header,
      content: DataAnalysis,
      sidePanel: DataPanel
    },
    beforeEnter: (_to, _from, next) => {
      const navigated = window.popStateDetected;
      window.popStateDetected = false;
      next(!navigated);
    }
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
