var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"canvas3D",attrs:{"id":_vm.name}},[_c('div',{staticClass:"action-buttons"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr5",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.resetCamera($event)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","light":"","color":"white"}},[_vm._v("mdi-restore")])],1)]}}])},[_c('span',[_vm._v("Reset Camera")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr5",attrs:{"fab":"","dark":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.pickCenter($event)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","light":"","color":"white"}},[_vm._v("mdi-target")])],1)]}}])},[_c('span',[_vm._v("Pick Rotation Center")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr5",attrs:{"fab":"","dark":"","small":"","disabled":_vm.morphologyReady},on:{"click":function($event){$event.stopPropagation();return _vm.toggleConvexHull($event)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","light":"","color":"white"}},[_vm._v("mdi-circle-double")])],1)]}}])},[_c('span',[_vm._v("Toggle Convex Hull")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr5",attrs:{"fab":"","dark":"","small":"","disabled":_vm.morphologyReady},on:{"click":function($event){$event.stopPropagation();return _vm.togglePlane($event)}}},on),[_c('v-icon',{attrs:{"small":"","dense":"","light":"","color":"white"}},[_vm._v("mdi-content-cut")])],1)]}}])},[_c('span',[_vm._v("Toggle Cutting Plane")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }