<template>
  <div>
    <AccountsModal :token="token" />
    <v-row no-gutters class="text-center">
      <v-col sm="8" offset-sm="2">
        <v-card class="mt50">
          <v-card-title>
            Cases
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details
              append-icon="mdi-feature-search-outline"
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-btn class="mr20" color="primary" @click="openDataAnalysis"
              >Data Analysis</v-btn
            >
            <v-dialog persistent v-model="dialog" width="500">
              <template v-slot:activator="{ on }">
                <v-btn color="primary" dark v-on="on">New Case</v-btn>
              </template>
              <v-card>
                <v-card-title>
                  <span class="headline">Add a new case</span>
                </v-card-title>
                <v-card-text>
                  <p>
                    Each user can create 10 free cases. If you'd like to create
                    more cases
                    <a href="mailto:info@dvisionlab.com">contact us</a> for
                    pay-per-case and flat offers.
                  </p>
                  <v-form v-model="valid">
                    <v-container>
                      <v-row>
                        <v-col sm="12">
                          <v-text-field
                            v-model="case_name"
                            required
                            :rules="nameRules"
                            :counter="10"
                            :disabled="saving"
                            prepend-icon="mdi-account-badge-horizontal-outline"
                            placeholder="Please choose a case name"
                          ></v-text-field>
                        </v-col>
                        <template v-if="!edit">
                          <v-col sm="12">
                            <v-file-input
                              v-model="file"
                              accept=".stl"
                              required
                              :rules="fileRules"
                              :disabled="saving"
                              show-size
                              label="Load a surface file (.stl)"
                              @change="onFileChange"
                            ></v-file-input>
                          </v-col>
                        </template>
                        <v-col sm="6">
                          <v-text-field
                            v-model="age"
                            type="number"
                            label="Age"
                          ></v-text-field>
                        </v-col>
                        <v-col sm="6">
                          <v-select
                            v-model="sex"
                            :items="sex_items"
                            label="Sex"
                          ></v-select>
                        </v-col>
                        <v-col sm="4">
                          <v-select
                            v-model="a_type"
                            :items="a_type_items"
                            label="Type"
                          ></v-select>
                        </v-col>
                        <v-col sm="4">
                          <v-select
                            v-model="a_site"
                            :items="a_site_items"
                            label="Site"
                          ></v-select>
                        </v-col>
                        <v-col sm="4">
                          <v-select
                            v-model="a_status"
                            :items="a_status_items"
                            label="Status"
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-alert v-if="error" normal :icon="false" type="error">
                  {{ errorLog }}
                </v-alert>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text :disabled="saving" @click="close"
                    >Cancel
                  </v-btn>
                  <v-btn
                    color="primary"
                    text
                    :disabled="saving || !valid"
                    :hidden="error"
                    @click="save"
                    >Save
                  </v-btn>
                </v-card-actions>
                <v-progress-linear
                  :active="saving"
                  indeterminate
                  striped
                  color="yellow darken-2"
                >
                </v-progress-linear>
                <p class="center-text">{{ dashboardLog }}</p>
              </v-card>
            </v-dialog>
          </v-card-title>
          <v-data-table
            :loading="loading"
            :headers="headers"
            :items="cases"
            :items-per-page="10"
            :search="search"
            :single-select="true"
            class="elevation-1"
            @click:row.once="openCase"
          >
            <template v-slot:item.edit="{ item }">
              <v-icon small @click.stop="editItem(item)">mdi-pencil</v-icon>
            </template>

            <template v-slot:item.delete="{ item }">
              <v-icon small @click.stop="deleteItem(item)">mdi-delete</v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import request from "superagent";
import { verify_and_refresh } from "@/common/api.users";

import { mapState } from "vuex";

import {
  createCase,
  openCase,
  updateCase,
  checkUserCases
} from "@/common/api.cases";

import AccountsModal from "@/components/AccountsModal";

export default {
  name: "Dashboard",
  data() {
    return {
      valid: false,
      userDialog: false,
      edit: false,
      nameRules: [
        v => !!v || "Case name is required",
        v => v.length <= 10 || "Case name must be less than 10 characters"
      ],
      fileRules: [v => (!!v && !this.edit) || "File is required"],
      case_name: "",
      sex: null,
      age: null,
      a_type: null,
      a_site: null,
      a_status: null,
      sex_items: [
        { text: "Male", value: "M" },
        { text: "Female", value: "F" },
        { text: "Other", value: "O" }
      ],
      a_site_items: [
        { text: "ACOM", value: "ACOM" },
        { text: "BA", value: "BA" },
        { text: "ICA", value: "ICA" },
        { text: "MCA", value: "MCA" },
        { text: "PCOM", value: "PCOM" },
        { text: "PICA", value: "PICA" }
      ],
      a_type_items: [
        { text: "Sidewall", value: "Sidewall" },
        { text: "Terminal", value: "Terminal" }
      ],
      a_status_items: [
        { text: "Ruptured", value: "Ruptured" },
        { text: "Unruptured", value: "Unruptured" }
      ],
      file: null,
      dialog: false,
      search: "",
      headers: [
        { text: "User", value: "user" },
        { text: "Case Name", value: "name" },
        { text: "Age", value: "age" },
        { text: "Sex", value: "sex" },
        { text: "Type", value: "a_type" },
        { text: "Site", value: "a_site" },
        { text: "Status", value: "a_status" },
        { text: "Date Created", value: "created_at" },
        { text: "Last Updated", value: "updated_at" },
        { text: "", value: "edit", sortable: false },
        { text: "", value: "delete", sortable: false }
      ],
      cases: [],
      caseId: null,
      loading: true,
      saving: false,
      error: false,
      errorlog: ""
    };
  },
  components: { AccountsModal },
  mounted: function() {
    if (this.token) {
      this.loadData();
    }
  },
  computed: {
    ...mapState({
      dashboardLog(state) {
        return state.dashboardLog;
      },
      token(state) {
        return state.accessToken;
      }
    })
  },
  watch: {
    token(value) {
      if (value) {
        this.loadData();
      } else {
        this.cases = [];
      }
    }
  },
  methods: {
    loadData() {
      let self = this;
      verify_and_refresh(function() {
        request
          .get("/api/cases/")
          .set("Authorization", "Bearer " + self.$store.state.accessToken)
          .then(function(resp) {
            self.cases = JSON.parse(resp.text).results;
            self.loading = false;
          });
      });
    },
    openDataAnalysis() {
      this.$router.push({ name: "dataAnalysis" });
      this.$store.dispatch("setCurrentPage", "dataAnalysis");
    },
    openCase(item) {
      openCase(item);
    },
    editItem(item) {
      this.edit = true;
      this.case_name = item.name;
      this.age = item.age;
      this.sex = item.sex;
      this.a_type = item.a_type;
      this.a_site = item.a_site;
      this.a_status = item.a_status;
      this.caseId = item.id;
      this.dialog = true;
    },
    deleteItem(item) {
      confirm("Are you sure you want to delete this case?") &&
        this.removeCase(item);
    },
    removeCase(item) {
      var self = this;
      verify_and_refresh(function() {
        request
          .delete("/api/case/" + item.id + "/")
          .set("Authorization", "Bearer " + this.$store.state.accessToken)
          .then(function() {
            const index = self.cases.indexOf(item);
            self.cases.splice(index, 1);
          });
      });
    },
    close() {
      this.dialog = false;
      this.case_name = "";
      this.file = null;
      this.age = null;
      this.sex = null;
      this.a_type = null;
      this.a_site = null;
      this.a_status = null;
      this.edit = false;
      this.error = false;
      this.errorlog = "";
    },
    save() {
      let self = this;
      this.saving = true;
      let caseData = {
        name: this.case_name,
        sex: this.sex,
        age: this.age,
        a_type: this.a_type,
        a_site: this.a_site,
        a_status: this.a_status
      };
      if (this.edit) {
        updateCase(this.caseId, caseData, function(resp) {
          if (resp.status == 200) {
            self.saving = false;
            self.error = false;
            self.close();
            self.loadData();
          } else {
            self.saving = false;
            self.error = true;
            self.errorLog = resp.text;
          }
        });
      } else {
        // check if user has reached free cases limit
        checkUserCases(function(hasReachedLimit) {
          if (hasReachedLimit) {
            self.saving = false;
            self.error = true;
            self.errorLog = "You have reached 10 free cases limit";
          } else {
            createCase(self.file, caseData, function(resp) {
              if (resp.status == 200) {
                self.saving = false;
                self.error = false;
                self.close();
                self.loadData();
              } else {
                self.saving = false;
                self.error = true;
                self.errorLog = resp.text;
              }
            });
          }
        });
      }
    },
    onFileChange(file) {
      this.file = file;
    }
  }
};
</script>

<style scoped>
.mt50 {
  margin-top: 50px;
}
.mr20 {
  margin-right: 20px;
}
.v-alert {
  font-size: small;
  text-align: left;
}
.center-text {
  text-align: center;
}
</style>
