import {
  Vector3,
  Geometry,
  LineBasicMaterial,
  Line,
  Plane,
  PlaneGeometry,
  MeshPhongMaterial,
  Mesh,
  AxisHelper,
  DoubleSide
} from "three";

import createTree from "yaot";

// global variables
const DEBUG = false;

//================================//
//=== CORE FUNCTION ==============//
//================================//

let findIntersection = function(bufferMesh, plane, allDots) {
  let verticesArray = bufferMesh.geometry.getAttribute("position").array;

  // get plane normal and constant
  let n = [plane.normal.x, plane.normal.y, plane.normal.z];
  let planeConstantNegated = [
    plane.normal.clone().multiplyScalar(plane.constant).x,
    plane.normal.clone().multiplyScalar(plane.constant).y,
    plane.normal.clone().multiplyScalar(plane.constant).z
  ];
  let d = -plane.constant;

  // initialize letiables: vD = difference vector, nP = dot product n*(first point vector), nD = dot product n*vD
  let pA,
    pB,
    pC,
    vectorSubA,
    vectorSubB,
    vectorSubC,
    vD,
    nP,
    nD = new Float32Array(3);
  let pointSideA, pointSideB, pointSideC;
  let dot = 0;
  let foundFirst = false;

  for (let cId = 0; cId < verticesArray.length; cId += 9) {
    // flag to manage 1-point case
    foundFirst = false;

    pA = [verticesArray[cId], verticesArray[cId + 1], verticesArray[cId + 2]];
    pB = [
      verticesArray[cId + 3],
      verticesArray[cId + 4],
      verticesArray[cId + 5]
    ];
    pC = [
      verticesArray[cId + 6],
      verticesArray[cId + 7],
      verticesArray[cId + 8]
    ];

    vectorSubA = [
      pA[0] + planeConstantNegated[0],
      pA[1] + planeConstantNegated[1],
      pA[2] + planeConstantNegated[2]
    ];
    vectorSubB = [
      pB[0] + planeConstantNegated[0],
      pB[1] + planeConstantNegated[1],
      pB[2] + planeConstantNegated[2]
    ];
    vectorSubC = [
      pC[0] + planeConstantNegated[0],
      pC[1] + planeConstantNegated[1],
      pC[2] + planeConstantNegated[2]
    ];

    pointSideA =
      n[0] * vectorSubA[0] + n[1] * vectorSubA[1] + n[2] * vectorSubA[2];
    pointSideB =
      n[0] * vectorSubB[0] + n[1] * vectorSubB[1] + n[2] * vectorSubB[2];
    pointSideC =
      n[0] * vectorSubC[0] + n[1] * vectorSubC[1] + n[2] * vectorSubC[2];

    // manage points lying on plane (avoid NaN in computing nD)

    if (pointSideA === 0) {
      allDots[dot] = pA[0];
      allDots[dot + 1] = pA[1];
      allDots[dot + 2] = pA[2];
      dot += 3;
      foundFirst = true;
    }
    if (pointSideB === 0) {
      allDots[dot] = pB[0];
      allDots[dot + 1] = pB[1];
      allDots[dot + 2] = pB[2];
      dot += 3;
      if (foundFirst) {
        continue;
      } else {
        foundFirst = true;
      }
    }
    if (pointSideC === 0) {
      allDots[dot] = pC[0];
      allDots[dot + 1] = pC[1];
      allDots[dot + 2] = pC[2];
      dot += 3;
      if (foundFirst) {
        continue;
      } else {
        foundFirst = true;
      }
    }

    // find intersections if points are on opposite sides

    if (pointSideA * pointSideB < 0) {
      // B-A, n*A, n*BA
      vD = [pB[0] - pA[0], pB[1] - pA[1], pB[2] - pA[2]];
      nP = n[0] * pA[0] + n[1] * pA[1] + n[2] * pA[2];
      nD = n[0] * vD[0] + n[1] * vD[1] + n[2] * vD[2];
      // store intersection point coordinates
      allDots[dot] = pA[0] + ((d - nP) / nD) * vD[0];
      allDots[dot + 1] = pA[1] + ((d - nP) / nD) * vD[1];
      allDots[dot + 2] = pA[2] + ((d - nP) / nD) * vD[2];
      dot += 3;
      if (foundFirst) {
        continue;
      } else {
        foundFirst = true;
      }
    }
    if (pointSideA * pointSideC < 0) {
      // C-A, n*A, n*CA
      vD = [pC[0] - pA[0], pC[1] - pA[1], pC[2] - pA[2]];
      nP = n[0] * pA[0] + n[1] * pA[1] + n[2] * pA[2];
      nD = n[0] * vD[0] + n[1] * vD[1] + n[2] * vD[2];
      // store intersection point coordinates
      allDots[dot] = pA[0] + ((d - nP) / nD) * vD[0];
      allDots[dot + 1] = pA[1] + ((d - nP) / nD) * vD[1];
      allDots[dot + 2] = pA[2] + ((d - nP) / nD) * vD[2];
      dot += 3;
      if (foundFirst) {
        continue;
      } else {
        foundFirst = true;
      }
    }
    if (pointSideB * pointSideC < 0) {
      // C-B, n*C, n*CA
      vD = [pC[0] - pB[0], pC[1] - pB[1], pC[2] - pB[2]];
      nP = n[0] * pB[0] + n[1] * pB[1] + n[2] * pB[2];
      nD = n[0] * vD[0] + n[1] * vD[1] + n[2] * vD[2];
      // store intersection point coordinates
      allDots[dot] = pB[0] + ((d - nP) / nD) * vD[0];
      allDots[dot + 1] = pB[1] + ((d - nP) / nD) * vD[1];
      allDots[dot + 2] = pB[2] + ((d - nP) / nD) * vD[2];
      dot += 3;
      if (foundFirst) {
        continue;
      } else {
        foundFirst = true;
      }
    }

    if (foundFirst) {
      allDots[dot] = allDots[dot - 3];
      allDots[dot + 1] = allDots[dot - 2];
      allDots[dot + 2] = allDots[dot - 1];
      dot += 3;
    }
  }

  allDots = allDots.slice(0, dot); //resize with max index

  return allDots;
};

//=====================================//
//== INTERPOLATE INTERSECTING POINTS ==//
//=====================================//

let drawContour = function(allDots, scene) {
  // let NoL = 0;
  let p1, p2;

  for (let i = 0; i < allDots.length; i += 6) {
    if (!isNaN(allDots[i])) {
      // NoL++;
      let lineGeometry = new Geometry();
      p1 = new Vector3(allDots[i], allDots[i + 1], allDots[i + 2]);
      p2 = new Vector3(allDots[i + 3], allDots[i + 4], allDots[i + 5]);
      lineGeometry.vertices.push(p1, p2);
      let lineMaterial = new LineBasicMaterial({
        color: 0x0000ff,
        linewidth: 2
      });
      let line = new Line(lineGeometry, lineMaterial);
      line.name = "line_" + i;
      scene.add(line);
    } else {
      console.warn("Nan at: ", i, allDots);
      break;
    }
  }
  // console.log("NoLines: ", NoL, "Array length", allDots.length);
  return;
};

//================================//
//=== CREATE PLANE ===============//
//================================//

let createPlane = function(origin, orientation) {
  let u = new Vector3(orientation[0], orientation[1], orientation[2]);
  let v = new Vector3(orientation[3], orientation[4], orientation[5]);
  let normalVect = u.cross(v);
  normalVect.normalize();
  let originVect = new Vector3();
  originVect.fromArray(origin);
  // originVect.projectOnVector(normalVect);
  // console.log(originVect)
  // console.log(normalVect)
  // let distance = -originVect.length();
  // let plane = new Plane(normalVect,distance);
  let plane = new Plane();
  plane.setFromNormalAndCoplanarPoint(normalVect, originVect);
  return plane;
};

//================================//
//=== FROM DOTS TO ij ============//
//================================//

let reportToij = function(points, origin, spacing, orientation, thickness) {
  let dotsList = [];
  // let lines = [];

  // TODO: remove 3js

  let u = new Vector3(orientation[0], orientation[1], orientation[2]);
  let v = new Vector3(orientation[3], orientation[4], orientation[5]);
  let normal = u.clone().cross(v);

  let nX = Math.abs(normal.clone().x);
  let nY = Math.abs(normal.clone().y);
  let nZ = Math.abs(normal.clone().z);

  if (nZ > nX && nZ > nY) {
    for (let k = 0; k < points.length; k += 3) {
      let i = Math.round(
        (points[k + 1] - origin[1]) / (spacing[0] * orientation[0])
      ); // pY = points[k+1]
      let j = Math.round(
        (points[k] - origin[0]) / (spacing[1] * orientation[4])
      ); // pX = points[k]

      // dotsList.push({ "start": i, "end": j });
      dotsList.push(i);
      dotsList.push(j);
    }
  } else if (nX > nZ && nX > nY) {
    for (let k = 0; k < points.length; k += 3) {
      let i = Math.round(
        (points[k + 1] - origin[1]) / (thickness * orientation[2])
      ); // pY = points[k+1]
      let j = Math.round(
        (points[k + 2] - origin[2]) / (spacing[1] * orientation[5])
      ); // pZ = points[k+2]

      dotsList.push(i);
      dotsList.push(j);
    }
  } else if (nY > nZ && nY > nX) {
    for (let k = 0; k < points.length; k += 3) {
      let i = Math.round(
        (points[k + 2] - origin[2]) / (thickness * orientation[0])
      ); // pZ = points[k+2]
      let j = Math.round(
        (points[k] - origin[0]) / (spacing[0] * orientation[5])
      ); // pX = points[k]

      dotsList.push(i);
      dotsList.push(j);
    }
  }

  // let i1, i2, j1, j2;
  // let start,
  //   end = {};

  // // TODO: check for speed

  // for (let d = 0; d < dotsList.length; d += 4) {
  //   i1 = dotsList[d];
  //   j1 = dotsList[d + 1];
  //   i2 = dotsList[d + 2];
  //   j2 = dotsList[d + 3];
  //   if (i1 !== i2 || j1 !== j2) {
  //     start = { x: i1, y: j1 };
  //     end = { x: i2, y: j2 };
  //     lines.push({ start: start, end: end });
  //   }
  // }

  return dotsList;
};

//=====================================//
//== GET BIGGEST NORMAL COMPONENT =====//
//=====================================//

const getDir = function(plane) {
  let normal = plane.normal;
  let dir = null;

  if (
    Math.abs(normal.x) >= Math.abs(normal.y) &&
    Math.abs(normal.x) >= Math.abs(normal.z)
  ) {
    dir = 0;
  }
  if (
    Math.abs(normal.y) >= Math.abs(normal.x) &&
    Math.abs(normal.y) >= Math.abs(normal.z)
  ) {
    dir = 1;
  }
  if (
    Math.abs(normal.z) >= Math.abs(normal.y) &&
    Math.abs(normal.z) >= Math.abs(normal.x)
  ) {
    dir = 2;
  }

  return dir;
};

//=====================================//
//== DRAW INTERSECTION PLANE  =========//
//=====================================//

let drawPlane = function(plane, scene, col, size) {
  let normal = plane.normal;
  let distance = -plane.constant;
  let translationVector = normal
    .clone()
    .normalize()
    .multiplyScalar(distance);
  // console.log(translationVector)
  let planeGeometry = new PlaneGeometry(size, size);
  let planeMaterial = new MeshPhongMaterial({
    color: col,
    side: DoubleSide,
    transparent: true,
    opacity: 0.4
  });
  let planeToDraw = new Mesh(planeGeometry, planeMaterial);
  planeToDraw.name = col;
  planeGeometry.lookAt(normal);
  planeGeometry.translate(
    translationVector.x,
    translationVector.y,
    translationVector.z
  );
  let axis = new AxisHelper(10);
  planeToDraw.add(axis);
  scene.add(planeToDraw);
  return planeToDraw;
};

//================================//
//=== CREATE DATA STRUCTURES =====//
//================================//

let createDataStruct = function(bufferArray) {
  // FILTERING BUFFER ARRAY TO AVOID DUPLICATES

  let time1 = Date.now();
  let verticesArray = new Float32Array(bufferArray);

  let coordMap = new Uint32Array(bufferArray.length / 3);
  let countArray = new Int32Array(bufferArray.length / 3);
  countArray.fill(-1);
  let counter = 0;
  let tree = createTree();
  tree.init(verticesArray);

  for (let i = 0; i < verticesArray.length; i += 3) {
    let currentVertex = [
      verticesArray[i],
      verticesArray[i + 1],
      verticesArray[i + 2]
    ];
    let matches = tree.intersectSphere(
      currentVertex[0],
      currentVertex[1],
      currentVertex[2],
      1e-20
    );

    coordMap[i / 3] = matches[0] / 3;

    if (countArray[coordMap[i / 3]] == -1) {
      countArray[i / 3] = counter;
      counter += 1;
    }
  }

  if (DEBUG) {
    console.log(coordMap);
    console.log(countArray);
  }

  // CREATING FILTERED VERTICES ARRAY

  let time2 = Date.now();
  let filteredVertices = new Float32Array(3 * counter);

  for (let commonIndex = 0; commonIndex < countArray.length; commonIndex++) {
    if (countArray[commonIndex] != -1) {
      let pId = countArray[commonIndex];
      let vertexCoord = [
        verticesArray[coordMap[commonIndex] * 3],
        verticesArray[coordMap[commonIndex] * 3 + 1],
        verticesArray[coordMap[commonIndex] * 3 + 2]
      ];
      filteredVertices[pId * 3] = vertexCoord[0];
      filteredVertices[pId * 3 + 1] = vertexCoord[1];
      filteredVertices[pId * 3 + 2] = vertexCoord[2];
    }
  }

  // CREATING CELLS ARRAY

  let time3 = Date.now();
  let cellsArray = new Uint32Array(coordMap);

  for (let j = 0; j < coordMap.length; j++) {
    cellsArray[j] = countArray[coordMap[j]];
  }

  // CREATING LINK ARRAYS

  let time4 = Date.now();
  let recurrenceCounter = new Uint32Array(filteredVertices.length / 3);
  recurrenceCounter.fill(1); // init 1 to keep one place free for each link set
  let idPositionArray = new Uint32Array(filteredVertices.length / 3);
  let pIdCorrente = null;

  for (let p = 0; p < cellsArray.length; p++) {
    pIdCorrente = cellsArray[p];
    recurrenceCounter[pIdCorrente]++;
  }

  let recurrenceSum = 0;
  for (let s = 0; s < recurrenceCounter.length; s++) {
    idPositionArray[s] = recurrenceSum;
    recurrenceSum = recurrenceSum + recurrenceCounter[s];
  }

  let linksArray = new Int32Array(recurrenceSum);
  linksArray.fill(-1);

  for (let u = 0; u < idPositionArray.length; u++) {
    linksArray[idPositionArray[u]] = recurrenceCounter[u] - 1;
  }

  if (DEBUG) {
    console.log(linksArray);
  }

  let offsetArray = new Uint32Array(filteredVertices.length / 3);
  offsetArray.fill(1);

  for (let t = 0; t < cellsArray.length; t++) {
    let currentPid = cellsArray[t];
    let currentCid = -1;

    switch (t % 3) {
      case 0:
        currentCid = t;
        break;
      case 1:
        currentCid = t - 1;
        break;
      case 2:
        currentCid = t - 2;
        break;
    }

    let position = idPositionArray[currentPid];
    let offset = offsetArray[currentPid];
    linksArray[position + offset] = currentCid;
    offsetArray[currentPid]++;
  }

  if (DEBUG) {
    console.log("time1: " + (time2 - time1));
    console.log("time2: " + (time3 - time2));
    console.log("time3: " + (time4 - time3));
    console.log("time4: " + (Date.now() - time4));

    console.log(verticesArray); // INPUT BUFFER ARRAY
    console.log(filteredVertices); // VERTICES ARRAY
    console.log(cellsArray); // CELLS ARRAY
    console.log(linksArray); // LINKS ARRAY
    console.log(idPositionArray); // ID POSITIONS INSIDE LINKS ARRAY
  }

  return [filteredVertices, cellsArray, linksArray, idPositionArray];
};

//================================//
//=== EXPORT FUNCTIONS ===========//
//================================//

export default {
  getDir: getDir,
  find: findIntersection,
  drawContour: drawContour,
  plane: createPlane,
  drawPlane: drawPlane,
  toij: reportToij,
  prepare: createDataStruct
};
