function initialState() {
  return {
    aneurysm_seed: [],
    planeOrigin: null,
    planeNormal: null,
    planeDistance: null,
    parameters: {}
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setAneurysmSeed(state, value) {
    state.aneurysm_seed = value;
  },
  removeAneurysmSeed(state) {
    state.aneurysm_seed = null;
  },
  setPlaneOrigin(state, value) {
    state.planeOrigin = value;
  },
  setPlaneNormal(state, value) {
    state.planeNormal = value;
  },
  setPlaneDistance(state, value) {
    state.planeDistance = value;
  },
  setParameters(state, value) {
    state.parameters = value;
  }
};

const actions = {
  setAneurysmSeed({ commit }, value) {
    commit("setAneurysmSeed", value);
  },
  removeAneurysmSeed({ commit }) {
    commit("removeAneurysmSeed");
  },
  setPlaneOrigin({ commit }, value) {
    commit("setPlaneOrigin", value);
  },
  setPlaneNormal({ commit }, value) {
    commit("setPlaneNormal", value);
  },
  setPlaneDistance({ commit }, value) {
    commit("setPlaneDistance", value);
  },
  setParameters({ commit }, value) {
    commit("setParameters", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
