// external libraries
import request from "superagent";

// internal libraries
import store from "@/store/index";

export function verify_and_refresh(callback) {
  let data = {
    token: store.state.accessToken
  };
  request
    .post("/auth/jwt/verify/")
    .send(data)
    .then(function() {
      callback();
    })
    .catch(function() {
      refreshToken(function() {
        callback();
      });
    });
}

export function refreshToken(callback) {
  let data = {
    refresh: store.state.refreshToken
  };
  request
    .post("/auth/jwt/refresh/")
    .send(data)
    .then(function(resp) {
      store.dispatch("setAccessToken", resp.body.access);
      callback();
    })
    .catch(error => {
      console.log(error);
    });
}

// ================================
// Login and get json web token ===
// ================================
export function jwt_login(email, password, callback) {
  let data = {
    email: email,
    password: password
  };
  request
    .post("/auth/jwt/create/")
    .send(data)
    .then(function(resp) {
      callback(resp);
    })
    .catch(error => {
      callback(error);
    });
}

// ================================
// Get current logged user info ===
// ================================
export function getCurrentUser(token, callback) {
  request
    .get("/auth/users/me/")
    .set("Authorization", "Bearer " + token)
    .then(function(resp) {
      callback(resp);
    })
    .catch(error => {
      callback(error);
    });
}

// ================================
// Set current logged user info ===
// ================================
export function setCurrentUser(token) {
  if (token) {
    getCurrentUser(token, function(resp) {
      store.dispatch("setUser", resp.body);
    });
  } else {
    store.dispatch("setUser", {});
  }
}

// ===========================================
// Create a user and send activation email ===
// ===========================================
export function createUser(firstname, lastname, password, email, callback) {
  let data = {
    email: email,
    firstname: firstname,
    lastname: lastname,
    password: password
  };
  request
    .post("/auth/users/")
    .send(data)
    .then(function(resp) {
      callback(resp);
    })
    .catch(error => {
      callback(error);
    });
}

// ================================================
// Reset password and send reset password email ===
// ================================================
export function resetPasswordWithEmail(email, callback) {
  let data = {
    email: email
  };
  request
    .post("/auth/users/reset_password/")
    .send(data)
    .then(function(resp) {
      callback(resp);
    })
    .catch(error => {
      callback(error);
    });
}
