<template>
  <div>
    <v-expansion-panel-header>Layout</v-expansion-panel-header>
    <v-expansion-panel-content v-if="activeChart == 'scatterChart'">
      <v-list-item v-for="serie in series" :key="serie.key">
        <v-row dense>
          <v-col cols="6">
            <v-list-item-title>{{ serie.name }}</v-list-item-title>
            <v-list-item-subtitle>Markers color and size</v-list-item-subtitle>
          </v-col>
          <v-col cols="2">
            <v-btn fab x-small outlined @click="toggleColorPicker(serie)">
              <v-icon dark :color="getSerieColor(serie)">mdi-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-slider
              :value="serie.marker.size"
              :min="min"
              :max="max"
              append-icon="mdi-plus"
              prepend-icon="mdi-minus"
              @click:append="increaseSize(serie)"
              @click:prepend="decreaseSize(serie)"
            ></v-slider>
          </v-col>
        </v-row>
      </v-list-item>
      <v-row justify="center" v-if="showPicker">
        <v-color-picker
          class="ma-2"
          :value="currentColor"
          @update:color="updateColor"
        ></v-color-picker>
      </v-row>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="activeChart == 'histChart'">
      <v-list-item v-for="serie in series" :key="serie.key">
        <v-row dense>
          <v-col cols="6">
            <v-list-item-title>{{ serie.name }}</v-list-item-title>
            <v-list-item-subtitle> Bars color </v-list-item-subtitle>
          </v-col>
          <v-col cols="2">
            <v-btn fab x-small outlined @click="toggleColorPicker(serie)">
              <v-icon dark :color="getSerieColor(serie)">mdi-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4"> </v-col>
        </v-row>
      </v-list-item>
      <v-row justify="center" v-if="showPicker">
        <v-color-picker
          class="ma-2"
          :value="currentColor"
          @update:color="updateColor"
        ></v-color-picker>
      </v-row>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="activeChart == 'radarChart'">
      <v-list-item v-for="serie in series" :key="serie.key">
        <v-row dense>
          <v-col cols="6">
            <v-list-item-title>{{ serie.name }}</v-list-item-title>
            <v-list-item-subtitle>Markers color and size</v-list-item-subtitle>
          </v-col>
          <v-col cols="2">
            <v-btn fab x-small outlined @click="toggleColorPicker(serie)">
              <v-icon dark :color="getSerieColor(serie)">mdi-circle</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="4">
            <v-slider
              :value="serie.marker.size"
              :min="min"
              :max="max"
              append-icon="mdi-plus"
              prepend-icon="mdi-minus"
              @click:append="increaseSize(serie)"
              @click:prepend="decreaseSize(serie)"
            ></v-slider>
          </v-col>
        </v-row>
      </v-list-item>
      <v-row justify="center" v-if="showPicker">
        <v-color-picker
          class="ma-2"
          :value="currentColor"
          @update:color="updateColor"
        ></v-color-picker>
      </v-row>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="activeChart == ''">
      <v-row>
        <v-col cols="10">
          Please select a chart type
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { updateChart } from "@/common/api.charts";

export default {
  data() {
    return {
      showPicker: false,
      size: 5,
      selectedSerie: null,
      currentColor: "red",
      min: 0,
      max: 20
    };
  },
  computed: {
    ...mapGetters(["serieColor"]),
    ...mapState({
      loading(state) {
        return state.dataAnalysis.loading;
      },
      series(state) {
        return state.dataAnalysis.currentSeries;
      },
      activeChart(state) {
        return state.dataAnalysis.chartType;
      }
    })
  },
  methods: {
    toggleColorPicker: function(serie) {
      if (!this.selectedSerie || this.selectedSerie == serie.name) {
        this.showPicker = !this.showPicker;
      }
      this.selectedSerie = this.showPicker ? serie.key : null;
      this.currentColor = serie.marker.color;
    },
    updateColor: function(obj) {
      this.$store.dispatch("dataAnalysis/setSerieColor", {
        key: this.selectedSerie,
        color: obj.hex
      });
      //TODO less impact function
      updateChart("chart-container", this.$store.state.dataAnalysis.chartType);
    },
    getSerieColor: function(serie) {
      let colorString = serie.marker.color.includes("#")
        ? serie.marker.color
        : "#" + serie.marker.color;
      return colorString;
    },
    increaseSize(serie) {
      let size = ++serie.marker.size;
      this.$store.dispatch("dataAnalysis/setSerieSize", {
        key: serie.key,
        size: size < 20 ? size : 20
      });
      updateChart("chart-container", this.$store.state.dataAnalysis.chartType);
    },
    decreaseSize(serie) {
      let size = --serie.marker.size;
      this.$store.dispatch("dataAnalysis/setSerieSize", {
        key: serie.key,
        size: size > 0 ? size : 0
      });
      updateChart("chart-container", this.$store.state.dataAnalysis.chartType);
    }
  },
  updated: function() {
    // console.log("updated");
  }
};
</script>

<style scoped>
.switch-aligned {
  margin-top: 14px;
}
</style>
