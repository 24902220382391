<template>
  <div>
    <v-expansion-panel-header>Chart</v-expansion-panel-header>
    <v-expansion-panel-content>
      <v-row justify="center">
        <v-radio-group row v-model="activeChart" :mandatory="false">
          <v-radio label="Scatter" value="scatterChart"></v-radio>
          <v-radio label="Histogram" value="histChart"></v-radio>
          <v-radio label="Radar" value="radarChart"></v-radio>
        </v-radio-group>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { updateChart } from "@/common/api.charts.js";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading(state) {
        return state.dataAnalysis.loading;
      }
    }),
    activeChart: {
      get() {
        return this.$store.state.dataAnalysis.chartType;
      },
      set(chart) {
        this.$store.dispatch("dataAnalysis/setChartType", chart);
        this.$store.dispatch("dataAnalysis/setActiveKey_x", "");
        this.$store.dispatch("dataAnalysis/setActiveKey_y", "");
      }
    }
  },
  watch: {
    activeChart: chart => {
      updateChart("chart-container", chart);
    }
  }
};
</script>

<style scoped>
.switch-aligned {
  margin-top: 14px;
}
.js-plotly-plot,
.plot-container,
.svg-container {
  height: 100%;
}
</style>
