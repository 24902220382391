// external libraries
import request from "superagent";

// internal libraries
import store from "@/store/index";
import {
  renderLine,
  import3DModel,
  renderCutPlane,
  toggleSurface,
  loadAneurysmSeed
} from "@/common/api.r3D";
import { renderPolyLine } from "@/common/api.centerline";
import { Vector3 } from "three";
import { each } from "lodash";

import { verify_and_refresh } from "@/common/api.users";

// ============================================
// Compute Centerline model from seeds input ==
// ============================================
export const computeMorphology = function(callback) {
  store.dispatch("viewer/setLoading", true);
  let caseId = store.state.caseId;
  let planeOrigin = store.state.morphology.planeOrigin;
  let planeNormal = store.state.morphology.planeNormal;
  let aneurysm_seed = store.state.morphology.aneurysm_seed;

  let data = {
    case_id: caseId,
    plane_center: [planeOrigin.x, planeOrigin.y, planeOrigin.z],
    plane_normal: [planeNormal.x, planeNormal.y, planeNormal.z],
    aneurysm_seed: [aneurysm_seed.x, aneurysm_seed.y, aneurysm_seed.z]
  };
  verify_and_refresh(function() {
    request
      .post("/api/compute_morphology/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function(resp) {
        store.dispatch("viewer/setLoading", false);
        callback(resp);
      })
      .catch(error => {
        store.dispatch("viewer/setLoading", false);
        callback(error.response);
      });
  });
};

// ============================================
// Compute Centerline model from seeds input ==
// ============================================
export const getMorphology = function(callback) {
  store.dispatch("viewer/setLoading", true);
  let morphologyId = store.state.morphologyId;
  verify_and_refresh(function() {
    request
      .get("/api/morphology/" + morphologyId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp) {
        if (resp.body.aneurysm_seed) {
          let aneurysm_seed = new Vector3(
            resp.body.aneurysm_seed[0],
            resp.body.aneurysm_seed[1],
            resp.body.aneurysm_seed[2]
          );
          store.dispatch("morphology/setAneurysmSeed", aneurysm_seed);
        }

        if (resp.body.plane_origin) {
          let plane_origin = new Vector3(
            resp.body.plane_origin[0],
            resp.body.plane_origin[1],
            resp.body.plane_origin[2]
          );
          store.dispatch("morphology/setPlaneOrigin", plane_origin);
        }

        if (resp.body.plane_normal) {
          let plane_normal = new Vector3(
            resp.body.plane_normal[0],
            resp.body.plane_normal[1],
            resp.body.plane_normal[2]
          );
          store.dispatch("morphology/setPlaneNormal", plane_normal);
        }

        if (resp.body.plane_width) {
          let plane_distance = new Vector3(
            resp.body.plane_width[0],
            resp.body.plane_width[1],
            resp.body.plane_width[2]
          );
          store.dispatch("morphology/setPlaneDistance", plane_distance);
        }

        if (resp.body.morphology_parameters) {
          store.dispatch(
            "morphology/setParameters",
            resp.body.morphology_parameters
          );
          import3DModel(
            {
              color: 0xffff00,
              opacity: 0.5,
              meshName: "convex_hull"
            },
            function() {
              renderCutPlane();
              toggleSurface("cutPoints");
            }
          );
          // render morphological objects
          renderLine(
            resp.body.morphology_parameters.aneurysmPerpendicularFoot,
            resp.body.morphology_parameters.aneurysmPerpendicularTip,
            "aneurysmPerpendicularHeight",
            { depthTest: true, color: 0x0000ff }
          );
          renderLine(
            resp.body.morphology_parameters.planeOrigin,
            resp.body.morphology_parameters.aneurysmTip,
            "aneurysmHeight",
            { depthTest: true, color: 0x0000ff }
          );
          renderPolyLine(
            "r3D",
            resp.body.morphology_parameters.surfaceNeck,
            "neck",
            { color: 0xff0000 }
          );
          renderPolyLine(
            "r3D",
            resp.body.morphology_parameters.surfaceBottleNeck,
            "bottleneck",
            { color: 0xffff00 }
          );
          loadAneurysmSeed();
          store.dispatch("viewer/setLoading", false);
          store.dispatch("viewer/setIsRendering", true);
          if (callback) {
            callback(resp);
          }
        } else {
          store.dispatch("viewer/setLoading", false);
        }
      })
      .catch(error => {
        store.dispatch("viewer/setLoading", false);
        if (callback) {
          callback(error.response);
        }
      });
  });
};

// ================================================
// Update the aneurysm seed into database model ===
// ================================================
export const updateAneurysmSeed = function(seed, callback) {
  let data = {
    case_id: store.state.caseId,
    aneurysm_seed: [seed.x, seed.y, seed.z]
  };
  verify_and_refresh(function() {
    request
      .post("/api/set_aneurysm_seed/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function(resp) {
        if (callback) {
          callback(resp);
        }
      })
      .catch(error => {
        if (callback) {
          callback(error.response);
        }
      });
  });
};

// ================================================
// Update the cutting plane into database model ===
// ================================================
export const updateCuttingPlane = function(
  planeOrigin,
  planeNormal,
  planeDistance,
  callback
) {
  let data = {
    case_id: store.state.caseId,
    plane_width: [planeDistance.x, planeDistance.y, planeDistance.z],
    plane_center: [planeOrigin.x, planeOrigin.y, planeOrigin.z],
    plane_normal: [planeNormal.x, planeNormal.y, planeNormal.z]
  };
  verify_and_refresh(function() {
    request
      .post("/api/set_plane/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function(resp) {
        if (callback) {
          callback(resp);
        }
      })
      .catch(error => {
        if (callback) {
          callback(error.response);
        }
      });
  });
};

export const exportDataToCsv = function() {
  let arrData = [];
  let paramObj = {
    caseName: store.state.activeCase.name,
    age: store.state.activeCase.age,
    sex: store.state.activeCase.sex,
    site: store.state.activeCase.a_site,
    type: store.state.activeCase.a_type,
    status: store.state.activeCase.a_status
  };
  let parameters = store.state.morphology.parameters;
  each(parameters, function(paramValue, paramKey) {
    if (
      paramKey != "surfaceNeck" &&
      paramKey != "surfaceBottleNeck" &&
      paramKey != "Error" &&
      paramValue.length == 1
    )
      paramObj[paramKey] = paramValue;
  });
  arrData.push(paramObj);
  let csvContent = "data:text/csv;charset=utf-8,";
  csvContent += [
    Object.keys(arrData[0]).join(";"),
    ...arrData.map(item => Object.values(item).join(";"))
  ]
    .join("\n")
    .replace(/(^\[)|(\]$)/gm, "");

  const data = encodeURI(csvContent);
  const link = document.createElement("a");
  link.setAttribute("href", data);
  link.setAttribute("download", store.state.activeCase.name + ".csv");
  link.click();
};
