// external library
import { Vector3, Plane, Geometry, Face3 } from "three";
import { sortBy, map, mean } from "lodash";

// ============================================
// From xyz array to THREE.Points array =======
// ============================================
export const plainArrayToThreePointsArray = function(array) {
  var threeArray = [];
  for (var p = 0; p < array.length; p += 3) {
    threeArray.push(new Vector3(array[p], array[p + 1], array[p + 2]));
  }
  return threeArray;
};

// ============================================
// From xyz array to THREE.Points array =======
// ============================================
export const threePointsArrayToPlainArray = function(threeArray) {
  var array = [];

  for (var p = 0; p < threeArray.length; p++) {
    array.push(threeArray[p].x);
    array.push(threeArray[p].y);
    array.push(threeArray[p].z);
  }

  return array;
};

// ============================================
// Compute geometry(s) centroIds ==============
// ============================================
export const getGeometryCentroid = function(geometries) {
  let minX, minY, minZ, maxX, maxY, maxZ, geometryFromArray, boundingBox;
  let center = new Vector3();
  let min = new Vector3();
  let max = new Vector3();
  if (geometries.length == 1) {
    geometryFromArray = geometries[0];
    geometryFromArray.computeBoundingBox();
    boundingBox = geometryFromArray.boundingBox;
    boundingBox.getCenter(center);
    minX = boundingBox.min.x;
    minY = boundingBox.min.y;
    minZ = boundingBox.min.z;
    maxX = boundingBox.max.x;
    maxY = boundingBox.max.y;
    maxZ = boundingBox.max.z;
    min = new Vector3(minX, minY, minZ);
    max = new Vector3(maxX, maxY, maxZ);
  } else {
    for (let i = 0; i < geometries.length; i++) {
      geometryFromArray = geometries[i];
      if (geometryFromArray) {
        geometryFromArray.computeBoundingBox();
        boundingBox = geometryFromArray.boundingBox;
        if (i == 0) {
          minX = boundingBox.min.x;
          minY = boundingBox.min.y;
          minZ = boundingBox.min.z;
          maxX = boundingBox.max.x;
          maxY = boundingBox.max.y;
          maxZ = boundingBox.max.z;
        } else {
          if (boundingBox.min.x < minX) {
            minX = boundingBox.min.x;
          }
          if (boundingBox.min.y < minY) {
            minY = boundingBox.min.y;
          }
          if (boundingBox.min.z < minZ) {
            minZ = boundingBox.min.z;
          }
          if (boundingBox.max.x > maxX) {
            maxX = boundingBox.max.x;
          }
          if (boundingBox.max.x > maxY) {
            maxY = boundingBox.max.y;
          }
          if (boundingBox.max.x > maxX) {
            maxZ = boundingBox.max.z;
          }
        }
        min = new Vector3(minX, minY, minZ);
        max = new Vector3(maxX, maxY, maxZ);
        center = new Vector3();
        center.addVectors(min, max).multiplyScalar(0.5);
      }
    }
  }
  return { center: center, min: min, max: max };
};

// ======================================
// Find center of a points cloud ========
// ======================================
export const getPointsCentroid = function(points) {
  let pts =
    points[0] instanceof Vector3
      ? points.slice()
      : plainArrayToThreePointsArray(points); // clone deep

  let xs = map(pts, "x");
  let ys = map(pts, "y");
  let zs = map(pts, "z");

  let center = new Vector3(mean(xs), mean(ys), mean(zs));

  return center;
};

// =============================================================
// Limit a point cloud inside (or outside) an array of planes ==
// =============================================================

export const limitIntersection = function(ptsArray_, planeArray, inside) {
  let ptsArray =
    ptsArray_[0] instanceof Vector3
      ? ptsArray_
      : plainArrayToThreePointsArray(ptsArray_);
  let res = [];

  // point / plane side :
  // sign(v = dot(Q - P, N))
  // Q pt
  // N normal
  // P origin

  let origin = new Vector3();

  // for each pt in array
  for (let i = 0; i < ptsArray.length; i++) {
    // for each plane compute side
    let sides = planeArray.map(pl => {
      let v = ptsArray[i].clone().sub(pl.coplanarPoint(origin));
      let side = Math.sign(pl.normal.dot(v));
      return side;
    });

    // check if all sides are "in" (or "out" if param "inside" is false)
    let dec = sides.reduce((acc, current) => {
      let v = acc && current > 0 == inside;
      return v;
    }, true);

    if (dec) {
      res.push(ptsArray[i]);
    }
  }

  return threePointsArrayToPlainArray(res);
};

// =======================================================
// Compute plane geometry from an intersection profile ===
// =======================================================

export const getCuttingPlaneGeometry = function(points_) {
  let points = plainArrayToThreePointsArray(points_);

  // get centroid
  let center = getPointsCentroid(points);

  // get max radius
  let ptsByRadius = sortBy(points, pt =>
    new Vector3().subVectors(center, pt).length()
  );

  let radius = new Vector3().subVectors(ptsByRadius.pop(), center);

  // get normal
  let nop = points.length;
  let plane = new Plane().setFromCoplanarPoints(
    points[0],
    points[Math.floor(nop / 3)],
    points[Math.floor((nop * 2) / 3)]
  );

  // get third direction
  let coradius = new Vector3().crossVectors(radius, plane.normal);

  // enlarge a bit
  radius.multiplyScalar(2);
  coradius.multiplyScalar(2);

  // compute corners position
  let corner_0 = new Vector3().addVectors(center, radius);
  let corner_1 = new Vector3().addVectors(center, coradius);
  let corner_2 = new Vector3().subVectors(center, radius);
  let corner_3 = new Vector3().subVectors(center, coradius);

  // corner_0 = corner_1 = corner_2 = corner_3 = new Vector3();

  // create geometry
  let geometry = new Geometry();
  geometry.vertices.push(corner_0);
  geometry.vertices.push(corner_1);
  geometry.vertices.push(corner_2);
  geometry.vertices.push(corner_3);
  geometry.faces.push(new Face3(0, 1, 2));
  geometry.faces.push(new Face3(0, 2, 3));

  return geometry;
};
