<template>
  <v-dialog v-model="dialog" light persistent scrollable width="60%">
    <template v-slot:activator="{ on: modal }">
      <v-btn block v-on="modal" class="mt10">
        Morphological Parameters Legend
      </v-btn>
    </template>

    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>
        Morphological Parameters
      </v-card-title>

      <v-card-text>
        <v-row class="justify">
          <v-col cols="12">
            <p>
              <b>Aneurysm inclination Angle</b> is the angle of inclination
              between the aneurysm and its neck plane. The line inside the
              aneurysm used for defining the angle is the connection of the neck
              centroid to the farthest point on the aneurysm dome. Aneurysms
              with daughter aneurysms and secondary growth are expected to have
              a higher value of this angle.
            </p>

            <p>
              <b>Inlet Vessel Angle</b> is the angle between the inlet vessel
              centerline and the neck plane. The centerline is approximated by
              the line connecting the centroids of the two vessel cross sections
              used to define “average vessel diameter” in Size Ratio Parameter.
            </p>

            <p>
              <b>Aneurysm Absolute Height</b> is the height of the aneurysm from
              the center of the neck plane to the tip of the aneurysm dome.
            </p>
            <p>
              <b>Aneurysm Perpendicular Height</b> is the height of the aneurysm
              from the center of the neck plane to the tip of the aneurysm dome
              in perpendicular direction.
            </p>
            <p>
              <b>Aneurysm Bottleneck Diameter</b> is the diameter calculated as
              twice the average distance from the bottleneck centroid to the
              edge of the bottleneck.
            </p>
            <p>
              <b>Aneurysm Neck Diameter</b> is the diameter calculated as twice
              the average distance from the neck centroid to the edge of the
              neck.
            </p>
            <p>
              <b>Parent Vessel Diameter</b> is the diameter calculated by
              measuring two representative vessel cross sections upstream of the
              aneurysm, calculating the local diameters in the same way as the
              neck diameter, and taking their average value.
            </p>
            <p>
              <b>Aspect Ratio (AR)</b> is the ratio of the maximum perpendicular
              height to the average neck diameter.
            </p>
            <p>
              <b>Size Ratio</b> is the aneurysm-to-vessel size ratio (SR) and
              incorporates the geometries of the aneurysm and its parent vessel
              and was defined as
              <i>(maximum aneurysm height)/(average vessel diameter)</i>. The
              maximum height in the above equation is not the maximum
              perpendicular height (H) used in the calculation of size and AR.
              Rather, it is the maximum (not necessarily perpendicular) distance
              from the centroid of the aneurysm neck to any point on the
              aneurysm dome (Hmax). Thus, SR captures the maximum deformation to
              the parent vessel caused by the outpouching of an aneurysm.
            </p>
            <p>
              <b>Convex Hull Surface Area</b> is the surface area of the convex
              hull.
            </p>
            <p>
              <b>Convex Hull Volume</b> is the smallest volume that fully
              encloses the aneurysm volume and that is convex at all points. It
              resembles a plastic wrap attached to the neck and stretched over
              the aneurysm surface.
            </p>
            <p>
              <b>Sac Surface Area</b> is the surface area of the aneurysm sac.
            </p>
            <p><b>Sac Volume</b> is the volume of the aneurysm sac.</p>
            <p>
              <b>Ellipticity index (EI)</b> characterizes the deviation of the
              aneurysm convex hull from that of a perfect hemisphere, and is
              thus a measure of aneurysm elongation. It is defined as
              <i>1 − (18π)1/3 Vch2/3/Sch</i>, where Sch is the surface area of
              the convex hull. Based on the convex hull, the EI is independent
              of undulations. In contrast to AR, which uses one-dimensional
              lengths, the EI characterizes aneurysm elongation based on 3D
              variables such as volume and surface area.
            </p>
            <p>
              <b>Undulation index (UI)</b> is defined as <i>1 − (V/Vch)</i>,
              where V is the volume of the aneurysm above the neck plane and Vch
              is the volume of the convex hull. This parameter captures the
              degree of aneurysm surface concavity, which can be significant
              when the aneurysmal sac has strong undulations or when daughter
              aneurysms are present. The UI increases with the number and
              severity of concave regions on the aneurysm surface. Conversely, a
              shape that is nonconcave (e.g., a perfect sphere or a cube) will
              have a UI of 0.
            </p>
            <p>
              <b>Non Sphericity index (NSI)</b> is similar to the EI, but it
              uses the actual aneurysm volume and surface area to characterize
              the deviation of the aneurysm geometry from that of a perfect
              hemisphere. It is defined as <i>1 − (18π)1/3 V2/3/S</i>, where S
              is the aneurysm surface area. This parameter is influenced by
              ellipticity and surface undulations and hence can be thought of as
              a combination of EI and UI.
            </p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-btn color="primary" text @click="close">Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false
    };
  },
  methods: {
    close: function() {
      this.dialog = false;
    }
  }
};
</script>

<style scoped>
.mt10 {
  margin-top: 10px;
}
.justify {
  text-align: justify;
}
</style>
