<template>
  <div class="h100">
    <v-card flat class="h90 scrollable">
      <v-card-text class="h90">
        <v-container fluid class="h90">
          <h1>Aneurometry Data Analysis</h1>

          <v-row class="aneurometry-menu" justify="center">
            <v-expansion-panels multiple popout>
              <v-expansion-panel> <Chart /> </v-expansion-panel>
              <v-expansion-panel> <Data /> </v-expansion-panel>
              <v-expansion-panel> <Layout /> </v-expansion-panel>
              <!-- TODO Table w/ data ? -->
            </v-expansion-panels>
          </v-row>

          <v-row class="aneurometry-menu">
            <v-col cols="12" align="center">
              <v-btn color="primary" dark medium @click="clearAll">
                <v-icon left>mdi-delete</v-icon> Clear All
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card flat class="h10">
      <v-card-text class="h100 text-center">
        <v-row class="h100">
          <v-col cols="6">
            <v-btn block color="primary" dark @click="exit">Exit</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn block color="primary" dark @click="exportData">Export</v-btn>
          </v-col>
        </v-row>
        <v-container fluid class="h10"> </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Data from "@/components/dataPanel/Data";
import Chart from "@/components/dataPanel/Chart";
import Layout from "@/components/dataPanel/Layout";

import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {};
  },
  components: {
    Data,
    Layout,
    Chart
  },
  computed: {
    ...mapGetters([]),
    ...mapState({
      loading(state) {
        return state.dataAnalysis.loading;
      }
    })
  },
  watch: {},
  methods: {
    exit() {
      this.$store.dispatch("clearState");
      this.$router.push({ name: "dashboard" });
      this.$store.dispatch("setCurrentPage", "dashboard");
    },
    clearAll() {
      this.$store.dispatch("dataAnalysis/clearState");
      // console.log("TODO clear all data");
    },
    exportData() {
      console.log("TODO export data");
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  overflow: hidden !important;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 90%;
}
.h10 {
  height: 10%;
}
.mt50 {
  margin-top: 50px;
}
.center-text {
  text-align: center;
}
.aneurometry-menu {
  margin-top: 30px;
}
.scrollable {
  overflow-y: auto;
}
</style>
