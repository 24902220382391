<template>
  <div class="viewer-container">
    <template>
      <v-row no-gutters class="text-center singleview-row">
        <v-col sm="12" class="singleview-container">
          <canvas3D name="r3D" viewer="viewer" activeViewport="r3D" />
        </v-col>
      </v-row>
    </template>
    <v-overlay :value="isLoading">
      <v-progress-circular
        class="color-dv"
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import canvas3D from "@/components/canvas3D";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Viewer",
  data() {
    return {};
  },
  components: {
    canvas3D
  },
  computed: {
    ...mapState({
      activeViewport(state) {
        return state.quadview.activeViewport;
      }
    }),
    ...mapGetters(["isR3DReady", "isR3DLoading"]),
    isLoading: function() {
      return !this.isR3DReady || this.isR3DLoading ? true : false;
    }
  },
  methods: {}
};
</script>

<style scoped>
.viewer-container {
  height: 100%;
}
.singleview-row {
  height: 100%;
}
.singleview-container {
  border: 0.5px solid #3f4a84;
}
.color-dv {
  color: #3f4a84 !important;
}
</style>
