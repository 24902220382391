<template>
  <v-app>
    <template v-if="currentPage != 'home'">
      <v-app-bar app dark min-width="750px">
        <router-view name="header" />
      </v-app-bar>
    </template>
    <v-content>
      <router-view name="content" />
      <template v-if="isMenuActive">
        <v-navigation-drawer
          class="noOverflow"
          app
          :permanent="isMenuActive"
          :width="500"
          right
        >
          <router-view name="sidePanel" />
        </v-navigation-drawer>
      </template>
    </v-content>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

// TEST DEV
// import { createCaseFromFile } from "@/../tests/unit/post_case";
// import { createUser } from "@/../tests/unit/create_user";
// import { getToken } from "@/../tests/unit/get_token";
// import { get } from "@/../tests/unit/get";
// import { resetPassword } from "@/../tests/unit/reset_password";

export default {
  name: "App",

  computed: {
    ...mapState({
      currentPage(state) {
        return state.currentPage;
      }
    }),
    isMenuActive: function() {
      let pagesWithMenu = ["viewer", "dataAnalysis"];
      return pagesWithMenu.includes(this.currentPage) ? true : false;
    }
  },
  mounted: function() {
    // TEST DEV
    // createCaseFromFile();
    // createUser();
    // getToken(function(token) {
    //   get("/auth/users/me/", token);
    //   get("/api/all-profiles/", token);
    //   get("/api/profile/7/", token);
    // });
    //resetPassword();
  }
};
</script>

<style>
/* .v-messages {
  display: none !important;
} */
.noOverflow > * {
  overflow: hidden !important;
}
</style>
