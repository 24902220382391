import Vue from "vue";
import Vuex from "vuex";

// modules
import viewer from "./modules/viewer";
import centerline from "./modules/centerline";
import morphology from "./modules/morphology";
import dataAnalysis from "./modules/dataAnalysis";

Vue.use(Vuex);

function initialState() {
  return {
    caseId: null,
    morphologyId: null,
    caseName: null,
    errorLog: "",
    dashboardLog: "",
    currentPage: "home",
    activeCase: null,
    accessToken: null,
    refreshToken: null
  };
}

export default new Vuex.Store({
  state: {
    ...initialState()
  },
  mutations: {
    reset(state) {
      // acquire initial state
      const s = initialState();
      Object.keys(s).forEach(key => {
        if (key != "accessToken" && key != "refreshToken") {
          state[key] = s[key];
        }
      });
    },
    setUser(state, value) {
      state.user = value;
    },
    setCaseCounter(state, value) {
      state.user.caseCounter = value;
    },
    setAccessToken(state, value) {
      state.accessToken = value;
    },
    setRefreshToken(state, value) {
      state.refreshToken = value;
    },
    setCurrentPage(state, value) {
      state.currentPage = value;
    },
    setCaseId(state, value) {
      state.caseId = value;
    },
    setCaseName(state, value) {
      state.caseName = value;
    },
    setMorphologyId(state, value) {
      state.morphologyId = value;
    },
    setActiveCase(state, value) {
      state.activeCase = value;
    }
  },
  actions: {
    clearState({ commit }) {
      commit("viewer/reset");
      commit("centerline/reset");
      commit("dataAnalysis/reset");
      commit("reset");
    },
    setUser({ commit }, value) {
      commit("setUser", value);
    },
    setCaseCounter({ commit }, value) {
      commit("setCaseCounter", value);
    },
    setAccessToken({ commit }, value) {
      commit("setAccessToken", value);
    },
    setRefreshToken({ commit }, value) {
      commit("setRefreshToken", value);
    },
    setCurrentPage({ commit }, value) {
      commit("setCurrentPage", value);
    },
    setCaseId({ commit }, value) {
      commit("setCaseId", value);
    },
    setCaseName({ commit }, value) {
      commit("setCaseName", value);
    },
    setMorphologyId({ commit }, value) {
      commit("setMorphologyId", value);
    },
    setActiveCase({ commit }, value) {
      commit("setActiveCase", value);
    }
  },
  getters: {
    isR3DReady: function() {
      return viewer.state.ready;
    },
    isR3DLoading: function() {
      return viewer.state.loading;
    },
    commonDataLoaded: function() {
      return !dataAnalysis.state.loading;
    }
  },
  modules: {
    viewer: viewer,
    centerline: centerline,
    morphology: morphology,
    dataAnalysis: dataAnalysis
  }
});
