<template>
  <div :id="name" class="canvas3D">
    <div class="action-buttons">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab dark small v-on="on" class="mr5" @click.stop="resetCamera">
            <v-icon small dense light color="white">mdi-restore</v-icon>
          </v-btn>
        </template>
        <span>Reset Camera</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn fab dark small v-on="on" class="mr5" @click.stop="pickCenter">
            <v-icon small dense light color="white">mdi-target</v-icon>
          </v-btn>
        </template>
        <span>Pick Rotation Center</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            v-on="on"
            :disabled="morphologyReady"
            class="mr5"
            @click.stop="toggleConvexHull"
          >
            <v-icon small dense light color="white">mdi-circle-double</v-icon>
          </v-btn>
        </template>
        <span>Toggle Convex Hull</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            fab
            dark
            small
            v-on="on"
            :disabled="morphologyReady"
            class="mr5"
            @click.stop="togglePlane"
          >
            <v-icon small dense light color="white">mdi-content-cut</v-icon>
          </v-btn>
        </template>
        <span>Toggle Cutting Plane</span>
      </v-tooltip>
    </div>
  </div>
</template>
<script>
import { resetCamera, togglePickCenter, toggleSurface } from "@/common/api.r3D";

export default {
  data() {
    return {};
  },
  props: ["name", "activeViewport", "viewer"],
  computed: {
    morphologyReady: function() {
      return this.$store.state.morphology.parameters ? false : true;
    }
  },
  methods: {
    resetCamera: function() {
      resetCamera();
    },
    pickCenter: function() {
      togglePickCenter();
    },
    toggleConvexHull: function() {
      toggleSurface("convex_hull");
    },
    togglePlane: function() {
      toggleSurface("cuttingPlane");
    }
  }
};
</script>

<style scoped>
.canvas3D {
  background-color: black;
  height: calc(100% - 6px); /* workaround */
  width: calc(100% - 1px);
}
.action-buttons {
  float: left;
  position: absolute;
  display: flex;
  padding: 0.5rem 0.5rem;
  z-index: 5;
  top: 0;
}
.mr5 {
  margin-right: 5px;
}
button:focus {
  background-color: #272727 !important;
  color: #272727 !important;
}
</style>
