import { render, staticRenderFns } from "./Data.vue?vue&type=template&id=596a74cc&scoped=true&"
import script from "./Data.vue?vue&type=script&lang=js&"
export * from "./Data.vue?vue&type=script&lang=js&"
import style0 from "./Data.vue?vue&type=style&index=0&id=596a74cc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "596a74cc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VOverflowBtn } from 'vuetify/lib/components/VOverflowBtn';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
installComponents(component, {VCheckbox,VCol,VExpansionPanelContent,VExpansionPanelHeader,VOverflowBtn,VRow,VSelect})
