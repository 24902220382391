function initialState() {
  return {
    seeds: [],
    centerlineIds: [],
    computed: false
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  addSeed(state, value) {
    state.seeds.push(value);
  },
  removeSeeds(state) {
    state.seeds = [];
  },
  removeLastSeed(state) {
    state.seeds = state.seeds.slice(0, -1);
  },
  addLine(state, value) {
    state.centerlineIds.push(value);
  },
  resetLines(state) {
    state.centerlineIds = [];
  },
  setComputed(state, value) {
    state.computed = value;
  }
};

const actions = {
  addSeed({ commit }, value) {
    commit("addSeed", value);
  },
  removeSeeds({ commit }) {
    commit("removeSeeds");
  },
  removeLastSeed({ commit }) {
    commit("removeLastSeed");
  },
  addLine({ commit }, value) {
    commit("addLine", value);
  },
  resetLines({ commit }) {
    commit("resetLines");
  },
  setComputed({ commit }, value) {
    commit("setComputed", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
