<template>
  <div>
    <section>
      <v-parallax src="/media/images/home-image-top.png" height="600">
        <v-layout column align-center justify-center class="white--text">
          <h1 class="white--text mb-2 display-1 text-center">Aneurometry</h1>
          <v-btn class="mt-12" color="#3F4A84" dark large @click="openViewer">
            Open Aneurometry
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-layout column wrap class="my-12 video-layout" align-center>
        <v-container grid-list-xl class="h100">
          <v-layout row wrap align-center class="h100">
            <v-flex xs12 md12 class="h100">
              <youtube
                :video-id="videoId"
                player-width="100%"
                player-height="600px"
                :player-vars="{ start: 1, autoplay: 1 }"
              ></youtube>
            </v-flex>
          </v-layout>
        </v-container>
      </v-layout>
    </section>

    <section>
      <v-layout column wrap class="my-12" align-center>
        <v-flex xs12 sm4 class="my-4">
          <div class="text-center">
            <h2 class="headline">
              Revolutionizes the way we approach brain aneurysms
            </h2>
            <span class="subheading">
              Automated 3D Morphology Analysis and Risk Assessment
            </span>
          </div>
        </v-flex>
        <v-flex xs12>
          <v-container grid-list-xl>
            <v-layout row wrap align-center>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="color-dv text--lighten-2"
                      >mdi-auto-fix</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">
                      Automated Computation
                    </div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    It allows to automatically compute aneurysm morphology from
                    surface models and to visualize in 3D an insight of the
                    individual aneurysm geometry sac and provides 16 parameters,
                    including size and neck diameter of the aneurysm.
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="color-dv text--lighten-2"
                      >mdi-database</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline">Morphological Parameters</div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Providing accurate morphometric information requires
                    extensive knowledge and computational skills. Therefore, a
                    mean to easily and accurately calculate such information is
                    necessary to assess morphometrics in clinical settings
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 md4>
                <v-card flat class="transparent">
                  <v-card-text class="text-center">
                    <v-icon x-large class="color-dv text--lighten-2"
                      >mdi-medical-bag</v-icon
                    >
                  </v-card-text>
                  <v-card-title primary-title class="layout justify-center">
                    <div class="headline text-center">
                      Risk Assessment
                    </div>
                  </v-card-title>
                  <v-card-text class="text-justify">
                    Aneurometry could potentially lead to better management.
                    Underestimation of aneurysm size can misguide clinical
                    decisions and underestimation of aneurysm neck diameter
                    could cause clinicians to improperly treat aneurysm
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
    </section>

    <section>
      <v-parallax src="/media/images/home-image-bottom.png" height="380">
        <v-layout column align-center justify-center>
          <div class="headline white--text mb-4 text-center">
            Morphology parameters for risk assessment
          </div>
          <em
            >Morphological parameters are important for risk assessment and
            treatment guidelines.</em
          >
          <v-btn class="mt-12" color="#3F4A84" dark large @click="openViewer">
            Open Aneurometry
          </v-btn>
        </v-layout>
      </v-parallax>
    </section>

    <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-12">
          <v-flex xs12 sm4>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">D/Vision Lab</div>
              </v-card-title>
              <v-card-text class="text-justify">
                We are an innovative start-up focused on data visualization.
                With over 15 years of experience accumulated by its founders,
                D/Vision Lab operates in scientific, medical, engineering and
                services domains allowing visual exploration of data through
                advanced visualization technologies and artificial intelligence
                to enable fast, intuitive and deep understanding of the business
                processes.
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn icon>
                  <a href="http://www.dvisionlab.com" target="blank">
                    <v-icon class="color-dv">mdi-web</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="mailto:info@dvisionlab.com" target="blank">
                    <v-icon class="color-dv">mdi-mail</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="http://www.github.com/dvisionlab" target="blank">
                    <v-icon class="color-dv">mdi-git</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a href="http://www.twitter.com/dvisionlab" target="blank">
                    <v-icon class="color-dv">mdi-twitter</v-icon>
                  </a>
                </v-btn>

                <v-btn icon>
                  <a
                    href="http://www.linkedin.com/company/dvisionlab"
                    target="blank"
                  >
                    <v-icon class="color-dv">mdi-linkedin</v-icon>
                  </a>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
          <v-flex xs12 sm4 offset-sm1>
            <v-card flat class="transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="headline">Contact Us</div>
              </v-card-title>
              <a href="http://www.dvisionlab.com" target="blank">
                <v-img
                  class="white--text align-end"
                  contain
                  src="/media/images/dvision_logo_2019_large_white.png"
                ></v-img>
              </a>
              <v-card-text class="text-justify"> </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>

    <v-footer color="#3F4A84">
      <v-layout row wrap align-center>
        <v-flex xs12>
          <div class="white--text ml-4">
            Made with
            <v-icon class="red--text">mdi-heart</v-icon>
            by
            <a class="white--text" href="http://dvisionlab.com" target="_blank"
              >D/Vision Lab</a
            >
          </div>
        </v-flex>
      </v-layout>
    </v-footer>
  </div>
</template>

<script>
import Vue from "vue";
import VueYouTubeEmbed from "vue-youtube-embed";

Vue.use(VueYouTubeEmbed);

export default {
  name: "Home",
  data() {
    return {
      videoId: "HggQI2UQT7A"
    };
  },
  methods: {
    openViewer() {
      this.$router.push({ name: "dashboard" });
      this.$store.dispatch("setCurrentPage", "dashboard");
    }
  }
};
</script>

<style scoped>
.v-application a {
  text-decoration: none;
}
.color-dv {
  color: #3f4a84 !important;
}
.video-layout {
  height: 600px;
}
.h100 {
  height: 100%;
}
</style>
