<template>
  <div>
    <v-expansion-panel-header
      >Aneurysm
      <template v-if="aneurysmSeed == true">
        <v-icon>mdi-check</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <p>
        Place a seed on the aneurysm, with left mouse button, to be used for
        morphological analysis
      </p>
      <v-row>
        <v-col cols="10">
          <v-switch
            v-model="seed"
            @change="togglePicking"
            dense
            inset
            label="Place a seed on the aneurysm"
            color="primary"
            hide-details
            :disabled="loading"
            id="aneurysm_seed"
          ></v-switch>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned mr-5"
                fab
                dark
                x-small
                :disabled="loading"
                @click="toggleSeeds"
              >
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Show/Hide seeds</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned"
                fab
                dark
                x-small
                :disabled="loading"
                @click="removeSeed"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete seed</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  activatePicking,
  deactivatePicking,
  removeAneurysmSeed,
  toggleAneurysmSeed
} from "@/common/api.r3D";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading(state) {
        return state.viewer.loading;
      },
      aneurysmSeed(state) {
        return !state.morphology.aneurysm_seed ||
          state.morphology.aneurysm_seed.length == 0
          ? false
          : true;
      }
    }),
    seed: {
      get() {
        return this.$store.state.viewer.aneurysmPicking;
      },
      set(value) {
        this.$store.commit("viewer/setAneurysmPicking", value);
        if (value) {
          this.$store.commit("viewer/setCenterlinePicking", !value);
          this.$store.commit("viewer/setPlanePicking", !value);
        }
      }
    }
  },
  methods: {
    togglePicking(value) {
      if (value) {
        activatePicking("aneurysmSeed", "model_" + this.$store.state.caseId);
      } else {
        deactivatePicking();
      }
    },
    removeSeed() {
      removeAneurysmSeed();
    },
    toggleSeeds() {
      toggleAneurysmSeed();
    }
  }
};
</script>

<style scoped>
.switch-aligned {
  margin-top: 14px;
}
</style>
