<template>
  <div class="h100">
    <v-card flat class="h90 scrollable">
      <v-card-text class="h90">
        <v-container fluid class="h90">
          <v-toolbar dense elevation="0">
            <v-toolbar-title>Aneurometry</v-toolbar-title>
          </v-toolbar>

          <v-row class="aneurometry-menu" justify="center">
            <v-expansion-panels popout>
              <v-expansion-panel> <Centerline /> </v-expansion-panel>
              <v-expansion-panel> <Aneurysm /> </v-expansion-panel>
              <v-expansion-panel> <Plane /> </v-expansion-panel>
              <v-expansion-panel :disabled="isMorphologyParametersDisabled">
                <Parameters />
              </v-expansion-panel>
            </v-expansion-panels>
          </v-row>

          <v-row class="aneurometry-menu" justify="center">
            <v-btn
              color="primary"
              dark
              block
              medium
              :disabled="isMorphologyRunDisabled"
              @click="runMorphology"
            >
              <v-icon left dark>mdi-cogs</v-icon> Compute Morphological
              Parameters
            </v-btn>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card flat class="h10">
      <v-card-text class="h100 text-center">
        <v-row class="h100">
          <v-col cols="6">
            <v-btn block color="primary" dark @click="exit">Exit</v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn
              block
              color="primary"
              dark
              @click="exportData"
              :disabled="isMorphologyParametersDisabled"
              >Export</v-btn
            >
          </v-col>
        </v-row>
        <v-container fluid class="h10"> </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Centerline from "@/components/sidePanel/Centerline";
import Aneurysm from "@/components/sidePanel/Aneurysm";
import Plane from "@/components/sidePanel/Plane";
import Parameters from "@/components/sidePanel/Parameters";

import { mapGetters, mapState } from "vuex";
import {
  initRenderingScene,
  removeRenderingScene,
  import3DModel
} from "@/common/api.r3D";
import { renderCenterline } from "@/common/api.centerline";
import {
  computeMorphology,
  getMorphology,
  exportDataToCsv
} from "@/common/api.morphology";

export default {
  data() {
    return {};
  },
  components: {
    Aneurysm,
    Centerline,
    Plane,
    Parameters
  },
  computed: {
    ...mapGetters(["isR3DReady"]),
    ...mapState({
      loading(state) {
        return state.viewer.loading;
      }
    }),
    isMorphologyRunDisabled: function() {
      if (this.loading) {
        return true;
      } else {
        if (
          this.$store.state.morphology.planeOrigin &&
          this.$store.state.morphology.planeNormal &&
          this.$store.state.morphology.aneurysm_seed != [] &&
          this.$store.state.centerline.computed
        ) {
          return false;
        } else {
          return true;
        }
      }
    },
    isMorphologyParametersDisabled: function() {
      return Object.keys(this.$store.state.morphology.parameters).length > 0
        ? false
        : true;
    }
  },
  mounted: function() {
    // initialize 3D scene
    setTimeout(function() {
      initRenderingScene("r3D", "r3D");
    }, 500);
  },
  watch: {
    isR3DReady: function(ready) {
      if (ready) {
        import3DModel({}, function() {
          renderCenterline(function() {
            getMorphology();
          });
        });
      }
    }
  },
  methods: {
    runMorphology() {
      computeMorphology(resp => {
        if (resp.status == 200) {
          getMorphology();
        }
      });
    },
    exit() {
      removeRenderingScene("r3D");
      this.$store.dispatch("clearState");
      this.$store.dispatch("setCurrentPage", "dashboard");
      this.$router.push({ name: "dashboard" });
    },
    exportData() {
      exportDataToCsv();
    }
  }
};
</script>

<style scoped>
.v-navigation-drawer {
  overflow: hidden !important;
}
.h100 {
  height: 100%;
}
.h90 {
  height: 90%;
}
.h10 {
  height: 10%;
}
.mt50 {
  margin-top: 50px;
}
.center-text {
  text-align: center;
}
.aneurometry-menu {
  margin-top: 20px;
}
.scrollable {
  overflow-y: auto;
}
</style>
