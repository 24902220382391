// external libraries
import request from "superagent";
import { CatmullRomCurve3, Vector3 } from "three";
import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { each } from "lodash";

// internal libraries
import store from "@/store/index";
import {
  changeOpacity,
  getRenderingScene,
  loadCenterlineSeeds
} from "@/common/api.r3D";
import { plainArrayToThreePointsArray } from "@/common/geometry/geometry.utils";

import { verify_and_refresh } from "@/common/api.users";

// global variables
let centerlineManager = {};

// ============================================
// Compute Centerline model from seeds input ==
// ============================================
export const computeCenterline = function(callback) {
  store.dispatch("viewer/setLoading", true);
  let caseId = store.state.caseId;
  let seeds = store.state.centerline.seeds;
  let data = {
    case_id: caseId,
    seeds: seeds
  };

  verify_and_refresh(function() {
    request
      .post("/api/compute_centerline/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(data)
      .then(function(resp) {
        store.dispatch("viewer/setLoading", false);
        callback(resp);
      })
      .catch(error => {
        store.dispatch("viewer/setLoading", false);
        callback(error.response);
      });
  });
};

// =================================
// Get centerline data and render ==
// =================================
export const renderCenterline = function(callback) {
  let caseId = store.state.caseId;
  store.dispatch("viewer/setLoading", true);
  // get case data
  verify_and_refresh(function() {
    request
      .get("/api/case/" + caseId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(caseResp) {
        let morphologyId = caseResp.body.morphology;
        if (morphologyId) {
          // get centerlines object
          request
            .get("/api/morphology/" + morphologyId + "/")
            .set("Authorization", "Bearer " + store.state.accessToken)
            .then(function(resp) {
              if (
                store.state.centerline.seeds.length === 0 &&
                resp.body.centerline
              ) {
                let inlet_seed = new Vector3(
                  resp.body.inlet_seed[0],
                  resp.body.inlet_seed[1],
                  resp.body.inlet_seed[2]
                );
                store.dispatch("centerline/addSeed", inlet_seed);
                for (let i = 0; i < resp.body.outlet_seeds.length; i += 3) {
                  let outlet_seed = new Vector3(
                    resp.body.outlet_seeds[i + 0],
                    resp.body.outlet_seeds[i + 1],
                    resp.body.outlet_seeds[i + 2]
                  );
                  store.dispatch("centerline/addSeed", outlet_seed);
                }
                // load seeds
                loadCenterlineSeeds();
              }
              // render centerlines
              let centerlineData = resp.body.centerline;
              if (centerlineData) {
                storeCenterline(centerlineData, caseId);
                // prepare scene
                changeOpacity("model_" + store.state.caseId, 0.5);
                store.dispatch("viewer/setIsRendering", true);
                initCenterlines("r3D", centerlineData);
                store.dispatch("centerline/setComputed", true);
                store.dispatch("viewer/setLoading", false);
                if (callback) {
                  callback();
                }
              } else {
                store.dispatch("viewer/setLoading", false);
              }
            });
        }
      })
      .catch(error => {
        store.dispatch("viewer/setLoading", false);
        callback(error.response);
      });
  });
};

// ===========================================
// Render a line for each centerline branch ==
// ===========================================
export const initCenterlines = function(sceneName, data) {
  // clean old centerline from the scene
  let scene = getRenderingScene(sceneName);
  let centerlines = store.state.centerline.centerlineIds;
  each(centerlines, function(centerlineId) {
    let centerline = scene.getObjectById(centerlineId);
    scene.remove(centerline);
    store.dispatch("centerline/resetLines");
  });
  let lines = [];
  each(data, (singleCenterline, centerlineId) => {
    let line = renderPolyLine(sceneName, singleCenterline.points, centerlineId);
    lines.push(line);
  });
  return lines;
};

// ==========================================
// Render a polyline from a set of points ===
// ==========================================
export const renderPolyLine = function(sceneName, linePoints, tag, options) {
  let scene = getRenderingScene(sceneName);
  let points = plainArrayToThreePointsArray(linePoints);
  let spline = new CatmullRomCurve3(points);
  let line = renderLine(scene, spline, tag, options);
  return line;
};

// =========================================
// Render a fat line from a spline object ==
// =========================================
function renderLine(scene, spline, tag, options) {
  // Position Data
  let positions = [];

  let divisions = Math.round(12 * spline.points.length);
  for (var i = 0, l = divisions; i < l; i++) {
    let point = spline.getPoint(i / l);
    positions.push(point.x, point.y, point.z);
  }
  let geometry = new LineGeometry();
  geometry.setPositions(positions);
  let matLine = new LineMaterial({
    color: options && options.color ? options.color : 0x00ff00,
    linewidth: 0.005,
    dashed: false
  });

  let line = new Line2(geometry, matLine);
  line.computeLineDistances();
  line.scale.set(1, 1, 1);
  line.name = "centerline_" + tag;
  if (scene.getObjectByName(line.name)) {
    scene.remove(scene.getObjectByName(line.name));
  }
  line.centerline = spline;
  store.dispatch("centerline/addLine", line.id);
  scene.add(line);
  return line;
}

// ===========================
// Store centerline locally ==
// ===========================
export const storeCenterline = function(centerlineData, caseId) {
  centerlineManager[caseId] = centerlineData;
};

// ======================
// Clear local storage ==
// ======================
export const clearCenterline = function() {
  centerlineManager = {};
};

// =========================
// Get from local storage ==
// =========================
export const getCenterlineData = function(caseId, arrayName) {
  let data = arrayName
    ? centerlineManager[caseId][arrayName]
    : centerlineManager[caseId];
  return data;
};
