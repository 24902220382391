<template>
  <div class="viewer-container">
    <template v-if="activeChart !== ''">
      <v-row no-gutters class="text-center singleview-row">
        <v-col sm="12" class="singleview-container">
          <div id="chart-container"></div>
        </v-col>
      </v-row>
    </template>
    <template v-if="activeChart == ''">
      <v-row justify="center" align="center">
        <v-chip
          large
          label
          class="ma-2 font-weight-bold"
          color="primary"
          text-color="white"
        >
          Please select a chart type from side panel
          <v-icon right x-large>mdi-arrow-right-bold-circle</v-icon>
        </v-chip>
      </v-row>
    </template>
    <v-overlay :value="loading">
      <v-progress-circular
        class="color-dv"
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getData } from "@/common/api.dataset";

export default {
  name: "DataAnalysis",
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading(state) {
        return state.dataAnalysis.loading;
      },
      activeChart(state) {
        return state.dataAnalysis.chartType;
      }
    })
  },
  mounted() {
    getData();
  },
  methods: {}
};
</script>

<style scoped>
.mt50 {
  margin-top: 50px;
}
.v-alert {
  font-size: small;
  text-align: left;
}
.center-text {
  text-align: center;
}
.viewer-container {
  height: 100%;
}
.singleview-row {
  height: 100%;
}
#chart-container {
  height: 100%;
}
</style>
