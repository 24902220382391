<template>
  <div>
    <v-expansion-panel-header
      >Morphological Parameters</v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <v-simple-table :dense="true">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Name</th>
              <th class="text-left">Value</th>
              <th class="text-left">UM</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Aneurysm inclination</td>
              <td>{{ parameters.aneurysmAngle[0].toFixed(2) }}</td>
              <td>°</td>
            </tr>
            <tr>
              <td>Inlet Vessel Angle</td>
              <td>{{ parameters.parentVesselAngle[0].toFixed(2) }}</td>
              <td>°</td>
            </tr>
            <tr>
              <td>Aneurysm Absolute Height</td>
              <td>{{ parameters.aneurysmHeight[0].toFixed(3) }}</td>
              <td>mm</td>
            </tr>
            <tr>
              <td>Aneurysm Perpendicular Height</td>
              <td>
                {{ parameters.aneurysmPerpendicularHeight[0].toFixed(3) }}
              </td>
              <td>mm</td>
            </tr>
            <tr>
              <td>Aneurysm Bottleneck Diameter</td>
              <td>{{ parameters.bottleneckDiameter[0].toFixed(3) }}</td>
              <td>mm</td>
            </tr>
            <tr>
              <td>Aneurysm Neck Diameter</td>
              <td>{{ parameters.neckDiameter[0].toFixed(3) }}</td>
              <td>mm</td>
            </tr>
            <tr>
              <td>Parent Vessel Diameter</td>
              <td>
                {{ parameters.parentVesselAverageDiameter[0].toFixed(3) }}
              </td>
              <td>mm</td>
            </tr>
            <tr>
              <td>Apect Ratio</td>
              <td>{{ parameters.aspectRatio[0].toFixed(3) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Size Ratio</td>
              <td>{{ parameters.sizeRatio[0].toFixed(3) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Convex Hull Surface Area</td>
              <td>{{ parameters.convexHullSurfaceArea[0].toFixed(2) }}</td>
              <td>mm<sup>2</sup></td>
            </tr>
            <tr>
              <td>Convex Hull Volume</td>
              <td>{{ parameters.convexHullVolume[0].toFixed(2) }}</td>
              <td>mm<sup>3</sup></td>
            </tr>
            <tr>
              <td>Sac Surface Area</td>
              <td>{{ parameters.sacArea[0].toFixed(2) }}</td>
              <td>mm<sup>2</sup></td>
            </tr>
            <tr>
              <td>Sac Volume</td>
              <td>{{ parameters.sacVolume[0].toFixed(2) }}</td>
              <td>mm<sup>3</sup></td>
            </tr>
            <tr>
              <td>Ellipticity index</td>
              <td>{{ parameters.ellipticityIndex[0].toFixed(3) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Undulation Index</td>
              <td>{{ parameters.undulationIndex[0].toFixed(3) }}</td>
              <td></td>
            </tr>
            <tr>
              <td>Non Sphericity Index</td>
              <td>{{ parameters.nonSphericityIndex[0].toFixed(3) }}</td>
              <td></td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <Legend />
    </v-expansion-panel-content>
  </div>
</template>

<script>
import Legend from "@/components/sidePanel/Legend";
import { mapState } from "vuex";

export default {
  data() {
    return {};
  },
  components: { Legend },
  computed: {
    ...mapState({
      parameters(state) {
        return state.morphology.parameters;
      }
    })
  },
  methods: {}
};
</script>

<style scoped></style>
