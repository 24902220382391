<template>
  <div class="w100">
    <a href="http://www.dvisionlab.com" target="_blank">
      <v-img
        src="/media/images/dvision_logo_2019_symbol.png"
        height="45"
        width="100"
        max-width="100"
        class="logo-brand"
        contain
      >
      </v-img>
    </a>

    <v-divider
      vertical
      color="primary"
      style="display: inline; vertical-align: middle;"
    ></v-divider>

    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <v-btn class="fr" icon v-on="on" dark @click="logout">
          <v-icon dark>mdi-exit-to-app</v-icon>
        </v-btn>
      </template>
      <span>Logout</span>
    </v-tooltip>

    <span class="version"> {{ appVersion }} </span>
    <v-img
      src="/media/images/logo.png"
      height="45"
      width="45"
      max-width="45"
      class="logo-product"
      contain
    >
    </v-img>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { version } from "@/../package.json";

import { setCurrentUser } from "@/common/api.users";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({}),
    appVersion: function() {
      return "v" + version;
    }
  },
  watch: {},
  methods: {
    logout: function() {
      this.$store.dispatch("setAccessToken", null);
      this.$store.dispatch("setRefreshToken", null);
      setCurrentUser(null);
    }
  }
};
</script>

<style scoped>
.w100 {
  width: 100%;
}
.ml5 {
  margin-left: 5px;
}
.mr5 {
  margin-right: 5px;
}
.logo-brand {
  float: left;
  margin-right: 10px;
}
.logo-product {
  float: right;
  margin-left: 10px;
}
.version {
  float: right;
  right: 70px;
  top: 20px;
  position: absolute;
  font-size: medium;
}
.v-application a {
  color: transparent !important;
}
.fr {
  float: right;
  position: absolute;
  right: 120px;
}
</style>
