import store from "@/store/index";
import request from "superagent";
import {
  map,
  assign,
  omitBy,
  isArray,
  mapValues,
  isString,
  isNumber,
  startCase
} from "lodash";

import { verify_and_refresh } from "@/common/api.users";

// TODO import data from DB
// import { literature_data } from "@/libs/literature_data.js";

export const dataManager = {
  user_data: [],
  literature_data: []
};

export const loadAxisKeys = function(axis) {
  let axisKeys = Object.keys(dataManager.user_data[0]);
  axisKeys.unshift("");
  store.dispatch(`dataAnalysis/set${axis}AxisKeys`, axisKeys);
  // Set default key
  // let defaultKey = axis == "x" ? "name" : axisKeys[0];
  // store.dispatch(`dataAnalysis/setActiveKey_${axis}`, defaultKey);
};

export const loadCasesList = function(axis) {
  let allData = dataManager.user_data;
  let showLiteratureData = store.state.dataAnalysis.showLiteratureData;
  if (showLiteratureData) {
    allData = allData.concat(dataManager.literature_data);
  }
  let caseKeys = map(allData, "key");
  store.dispatch(`dataAnalysis/set${axis}AxisKeys`, caseKeys);
};

export const getCommonData = function(cb) {
  verify_and_refresh(function() {
    request
      .get("/api/commondataset/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp) {
        dataManager.literature_data = map(resp.body.results, d => {
          let entry = d.morphology_parameters;
          entry.name = startCase(d.name);
          entry.key = d.name;
          return entry;
        });

        if (cb) {
          cb();
        }
      })
      .catch(error => {
        if (cb) {
          cb(error.response);
        }
      });
  });
};

const mergeUserData = function(cases, morphologies) {
  let merged = map(cases, caseData => {
    let entry = {
      name: startCase(caseData.name),
      key: caseData.name,
      age: caseData.age,
      sex: caseData.sex,
      a_type: caseData.a_type,
      a_site: caseData.a_site,
      a_status: caseData.a_status
    };

    let morphData = morphologies.filter(m => m.id == caseData.morphology).pop()
      .morphology_parameters;
    assign(entry, morphData);
    // remove arrays longer than 1
    entry = omitBy(entry, v => {
      return !isNumber(v) && !isString(v) && isArray(v) && v.length > 1;
    });

    // for single-value arrays, keep internal value
    entry = mapValues(entry, v => (isArray(v) ? v[0] : v));

    return entry;
  });

  return merged;
};

export const getUserData = function(cb) {
  verify_and_refresh(function() {
    request
      .get("/api/cases/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp_cases) {
        let cases = resp_cases.body.results;
        cases = cases.filter(c => c.morphology != null);
        // filter cases if morphology exists
        request
          .get("/api/morphologies/")
          .set("Authorization", "Bearer " + store.state.accessToken)
          .then(function(resp_morp) {
            let morphs = resp_morp.body.results;
            dataManager.user_data = mergeUserData(cases, morphs);

            if (cb) {
              cb();
            }
          })
          .catch(error => {
            if (cb) {
              cb(error.response);
            }
          });
      })
      .catch(error => {
        if (cb) {
          cb(error.response);
        }
      });
  });
};

export const getData = function() {
  store.dispatch("dataAnalysis/setLoading", true);

  getCommonData(() => {
    getUserData(() => {
      store.dispatch("dataAnalysis/setLoading", false);
    });
  });
};
