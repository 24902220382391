<template>
  <div>
    <v-expansion-panel-header>Data</v-expansion-panel-header>
    <v-expansion-panel-content v-if="activeChart == 'scatterChart'">
      <v-row>
        <v-col cols="6">
          <template>
            <v-overflow-btn
              dense
              :items="xAxisKeys"
              hint="X Axis"
              persistent-hint
              v-model="activeKeyX"
            ></v-overflow-btn>
          </template>
        </v-col>
        <v-col cols="6">
          <template>
            <v-overflow-btn
              dense
              :items="yAxisKeys"
              hint="Y Axis"
              persistent-hint
              v-model="activeKeyY"
            ></v-overflow-btn>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="showLiteratureData"
            label="Show data from literature"
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="activeChart == 'histChart'">
      <v-row>
        <v-col cols="12">
          <template>
            <v-overflow-btn
              dense
              :items="xAxisKeys"
              hint="Variable"
              persistent-hint
              v-model="activeKeyX"
            ></v-overflow-btn>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="showLiteratureData"
            label="Show data from literature"
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="activeChart == 'radarChart'">
      <v-row no-gutter>
        <v-col cols="12">
          <v-select
            :items="yAxisKeys"
            label="Cases to Include"
            multiple
            chips
            hint="Cases to Include"
            persistent-hint
            v-model="activeKeyY"
          ></v-select>
        </v-col>
      </v-row>
      <v-row no-gutter>
        <v-col cols="12">
          <v-select
            :items="xAxisKeys"
            label="Variables to Include"
            multiple
            chips
            hint="Variables to Include"
            persistent-hint
            v-model="activeKeyX"
          ></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-checkbox
            v-model="showLiteratureData"
            label="Show data from literature"
            dense
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <v-expansion-panel-content v-if="activeChart == ''">
      <v-row>
        <v-col cols="10">
          Please select a chart type
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { loadAxisKeys, loadCasesList } from "@/common/api.dataset";
import { updateChart } from "@/common/api.charts";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading(state) {
        return state.dataAnalysis.loading;
      },
      xAxisKeys(state) {
        return state.dataAnalysis.xAxisKeys;
      },
      yAxisKeys(state) {
        return state.dataAnalysis.yAxisKeys;
      },
      activeChart(state) {
        return state.dataAnalysis.chartType;
      }
    }),
    activeKeyX: {
      get() {
        return this.$store.state.dataAnalysis.activeKeyX;
      },
      set(key) {
        this.$store.dispatch("dataAnalysis/setActiveKey_x", key);
        updateChart(
          "chart-container",
          this.$store.state.dataAnalysis.chartType
        );
      }
    },
    activeKeyY: {
      get() {
        return this.$store.state.dataAnalysis.activeKeyY;
      },
      set(key) {
        this.$store.dispatch("dataAnalysis/setActiveKey_y", key);
        updateChart(
          "chart-container",
          this.$store.state.dataAnalysis.chartType
        );
      }
    },
    showLiteratureData: {
      get() {
        return this.$store.state.dataAnalysis.showLiteratureData;
      },
      set(toggle) {
        this.$store.dispatch("dataAnalysis/setLiteratureData", toggle);
        updateChart(
          "chart-container",
          this.$store.state.dataAnalysis.chartType
        );
      }
    }
  },
  watch: {
    activeChart: chart => {
      if (chart == "scatterChart") {
        loadAxisKeys("x");
        loadAxisKeys("y");
      } else if (chart == "histChart") {
        loadAxisKeys("x");
      } else if (chart == "radarChart") {
        loadAxisKeys("x");
        loadCasesList("y");
      }
    },
    showLiteratureData: function() {
      if (this.activeChart == "radarChart") {
        // refresh case list
        loadCasesList("y");
      }
    }
  },
  methods: {}
};
</script>

<style scoped>
.switch-aligned {
  margin-top: 14px;
}
</style>
