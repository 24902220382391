<template>
  <div>
    <v-expansion-panel-header
      >Neck Plane
      <template v-if="neckPlaneDefined == true">
        <v-icon>mdi-check</v-icon>
      </template></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <p>
        Define the neck plane by clicking on the 3D scene with left mouse button
        and drag a line representing the cutting plane. Confirm the end point by
        clicking again with left mouse button.
      </p>
      <v-row>
        <v-col cols="10">
          <v-switch
            v-model="plane"
            @change="togglePlane"
            dense
            inset
            label="Drag and drop to define neck plane"
            color="primary"
            hide-details
            :disabled="loading"
            id="plane"
          ></v-switch>
        </v-col>
        <v-col cols="2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned"
                fab
                dark
                x-small
                :disabled="loading"
                @click="removePlane"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete plane</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapState } from "vuex";

import { activatePlane, deactivatePlane, removePlane } from "@/common/api.r3D";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading(state) {
        return state.viewer.loading;
      },
      neckPlaneDefined(state) {
        return state.morphology.planeOrigin && state.morphology.planeNormal
          ? true
          : false;
      }
    }),
    plane: {
      get() {
        return this.$store.state.viewer.planePicking;
      },
      set(value) {
        this.$store.commit("viewer/setPlanePicking", value);
        if (value) {
          this.$store.commit("viewer/setAneurysmPicking", !value);
          this.$store.commit("viewer/setCenterlinePicking", !value);
        }
      }
    }
  },
  methods: {
    togglePlane(value) {
      if (value) {
        activatePlane();
      } else {
        deactivatePlane();
      }
    },
    removePlane() {
      removePlane();
    }
  }
};
</script>

<style scoped>
.switch-aligned {
  margin-top: 14px;
}
</style>
