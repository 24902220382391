// external libraries
import request from "superagent";

// internal libraries
import store from "@/store/index";
import router from "@/router/index";

import { verify_and_refresh } from "@/common/api.users";

// =================
// Open a case =====
// =================
export const openCase = function(item) {
  router.push({ name: "viewer" });
  store.dispatch("setCaseId", item.id);
  store.dispatch("setMorphologyId", item.morphology);
  store.dispatch("setCaseName", item.name);
  store.dispatch("setActiveCase", item);
  store.dispatch("setCurrentPage", "viewer");
};

// =================================
// Create case from file object ====
// =================================
export async function createCase(file, caseData, callback) {
  verify_and_refresh(function() {
    request
      .post("/api/cases/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .attach("surface_model", file, {
        type: "application/octet-stream"
      })
      .field("userId", store.state.user.id)
      .field("caseCounter", parseInt(store.state.user.caseCounter) + 1)
      .field("name", caseData.name)
      .field("sex", caseData.sex ? caseData.sex : "")
      .field("age", caseData.age ? caseData.age : 0)
      .field("a_type", caseData.a_type ? caseData.a_type : "")
      .field("a_site", caseData.a_site ? caseData.a_site : "")
      .field("a_status", caseData.a_status ? caseData.a_status : "")
      .then(function(resp) {
        callback(resp);
      })
      .catch(error => {
        callback(error);
      });
  });
}

// =====================
// Check user cases ====
// =====================
export async function checkUserCases(callback) {
  verify_and_refresh(function() {
    request
      .get("/api/profile/" + store.state.user.id + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .then(function(resp) {
        store.dispatch("setCaseCounter", resp.body.caseCounter);
        callback(resp.body.caseCounter >= resp.body.casesLimit);
      })
      .catch(error => {
        callback(error);
      });
  });
}

// =====================
// Update case data ====
// =====================
export async function updateCase(caseId, caseData, callback) {
  verify_and_refresh(function() {
    request
      .put("/api/case/" + caseId + "/")
      .set("Authorization", "Bearer " + store.state.accessToken)
      .send(caseData)
      .then(function(resp) {
        callback(resp);
      })
      .catch(error => {
        callback(error);
      });
  });
}
