<template>
  <div>
    <v-expansion-panel-header
      >Centerline
      <template v-if="centerlineComputed == true">
        <v-icon>mdi-check</v-icon>
      </template></v-expansion-panel-header
    >
    <v-expansion-panel-content>
      <p>
        Place first the inlet seed and then outlet seeds. Finally click the run
        button to compute and render centerline
      </p>
      <v-row>
        <v-col cols="8">
          <v-switch
            v-model="seeds"
            @change="togglePicking"
            dense
            inset
            label="Place inlet and outlet seeds"
            color="primary"
            hide-details
            :disabled="loading"
            id="centerline_seeds"
          ></v-switch>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned mr-5"
                fab
                dark
                x-small
                :disabled="loading"
                @click="toggleSeeds"
              >
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>Show/Hide seeds</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned mr-5"
                fab
                dark
                x-small
                :disabled="loading"
                @click="removeLastSeed"
              >
                <v-icon dark>mdi-restore</v-icon>
              </v-btn>
            </template>
            <span>Delete last seed</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned mr-5"
                fab
                dark
                x-small
                :disabled="loading"
                @click="removeSeeds"
              >
                <v-icon dark>mdi-delete</v-icon>
              </v-btn>
            </template>
            <span>Delete all seeds</span>
          </v-tooltip>
        </v-col>
        <v-col cols="1">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="switch-aligned"
                fab
                dark
                x-small
                :disabled="loading || notReady"
                @click="computeCenterline"
              >
                <v-icon dark color="red">mdi-cogs</v-icon>
              </v-btn>
            </template>
            <span>Compute Centerline</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
  </div>
</template>

<script>
import { mapState } from "vuex";

import {
  activatePicking,
  deactivatePicking,
  removeLastCenterlineSeed,
  removeCenterlineSeeds,
  toggleCenterlineSeeds
} from "@/common/api.r3D";

import { computeCenterline, renderCenterline } from "@/common/api.centerline";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState({
      loading(state) {
        return state.viewer.loading;
      },
      centerlineComputed(state) {
        return state.centerline.computed ? true : false;
      }
    }),
    notReady: function() {
      if (this.loading) {
        return true;
      } else {
        if (this.$store.state.centerline.seeds.length < 2) {
          return true;
        } else {
          return false;
        }
      }
    },
    seeds: {
      get() {
        return this.$store.state.viewer.centerlinePicking;
      },
      set(value) {
        this.$store.commit("viewer/setCenterlinePicking", value);
        if (value) {
          this.$store.commit("viewer/setAneurysmPicking", !value);
          this.$store.commit("viewer/setPlanePicking", !value);
        }
      }
    }
  },
  methods: {
    togglePicking(value) {
      if (value) {
        activatePicking("centerlineSeeds", "model_" + this.$store.state.caseId);
      } else {
        deactivatePicking();
      }
    },
    toggleSeeds() {
      toggleCenterlineSeeds();
    },
    removeSeeds() {
      removeCenterlineSeeds();
    },
    removeLastSeed() {
      removeLastCenterlineSeed();
    },
    computeCenterline() {
      computeCenterline(resp => {
        if (resp.status == 200) {
          renderCenterline();
        }
      });
    }
  }
};
</script>

<style scoped>
.switch-aligned {
  margin-top: 14px;
}
.mr-5 {
  margin-right: 5px;
}
</style>
