function initialState() {
  return {
    isRendering: false,
    controls: true,
    centerlinePicking: false,
    aneurysmPicking: false,
    planePicking: false,
    cameraPosition: {},
    customRotatioCenter: null,
    ready: false,
    loading: false
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setIsRendering(state, value) {
    state.isRendering = value;
  },
  setControls(state, value) {
    state.controls = value;
  },
  setViewerStatus(state, value) {
    state.ready = value;
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setCenterlinePicking(state, value) {
    state.centerlinePicking = value;
  },
  setAneurysmPicking(state, value) {
    state.aneurysmPicking = value;
  },
  setPlanePicking(state, value) {
    state.planePicking = value;
  },
  setCameraPosition(state, value) {
    state.cameraPosition = value;
  },
  setCustomRotationCenter(state, value) {
    state.customRotationCenter = value;
  }
};

const actions = {
  setIsRendering({ commit }, value) {
    commit("setIsRendering", value);
  },
  setControls({ commit }, value) {
    commit("setControls", value);
  },
  setViewerStatus({ commit }, value) {
    commit("setViewerStatus", value);
  },
  setLoading({ commit }, value) {
    commit("setLoading", value);
  },
  setCenterlinePicking({ commit }, value) {
    commit("setCenterlinePicking", value);
  },
  setAneurysmPicking({ commit }, value) {
    commit("setAneurysmPicking", value);
  },
  setPlanePicking({ commit }, value) {
    commit("setPlanePicking", value);
  },
  setCameraPosition({ commit }, value) {
    commit("setCameraPosition", value);
  },
  setCustomRotationCenter({ commit }, value) {
    commit("setCustomRotationCenter", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
