// external libraries
import {
  AmbientLight,
  BufferAttribute,
  BufferGeometry,
  DoubleSide,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  Matrix4,
  Mesh,
  MeshBasicMaterial,
  MeshLambertMaterial,
  MeshPhongMaterial,
  OrthographicCamera,
  PCFSoftShadowMap,
  PointLight,
  Points,
  PointsMaterial,
  Plane,
  PlaneGeometry,
  Raycaster,
  Scene,
  SphereGeometry,
  Vector3,
  WebGLRenderer
} from "three";

import { Line2 } from "three/examples/jsm/lines/Line2";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry";
import { TrackballControls } from "three/examples/jsm/controls/TrackballControls";

import request from "superagent";
import { verify_and_refresh } from "@/common/api.users";
import { cloneDeep, each, has } from "lodash";

// internal libraries
import store from "@/store/index";
import intersect from "@/libs/intersect";
import {
  updateAneurysmSeed,
  updateCuttingPlane
} from "@/common/api.morphology";

import {
  limitIntersection,
  getCuttingPlaneGeometry,
  getGeometryCentroid
} from "@/common/geometry/geometry.utils";

// global variables
var requestId = null;
var controls = null;
var renderer = null;
var scenes = {}; // sceneName: scene3D
const frustumSize = 50;
var pickingFunctions = {
  centerlineSeeds: centerlineSeeds,
  aneurysmSeed: aneurysmSeed,
  planeDefinition: planeDefinition
};

// get width and height of the canvas
function getCanvasSize() {
  let containerId = containerId ? containerId : "r3D";
  return [
    document.getElementById(containerId).offsetWidth,
    document.getElementById(containerId).offsetHeight
  ];
}

// resize canvas on window resize
function onWindowResize() {
  let camera = scenes["r3D"].getObjectByName("camera");
  let canvasSize = getCanvasSize();

  camera.left = canvasSize[0] / -frustumSize;
  camera.right = canvasSize[0] / frustumSize;
  camera.top = canvasSize[1] / frustumSize;
  camera.bottom = canvasSize[1] / -frustumSize;
  camera.updateProjectionMatrix();
  renderer.setSize(canvasSize[0], canvasSize[1]);
  if (controls) {
    controls.handleResize();
  }
  store.dispatch("viewer/setIsRendering", true);
}

// ==================
// Setup 3D scene ===
// ==================
export const initRenderingScene = function(containerId, sceneName) {
  let canvasWidth = document.getElementById(containerId).offsetWidth;
  let canvasHeight = document.getElementById(containerId).offsetHeight;

  var scene3d;

  if (!requestId) {
    init();
    animate();
    store.dispatch("viewer/setIsRendering", false);
  }

  function init() {
    var startRender = function() {
      store.dispatch("viewer/setIsRendering", true);
    };

    var onMouseDown = function() {
      startRender();
      renderer.domElement.addEventListener("mousemove", startRender, false);
    };

    scene3d = new Scene();
    scene3d.name = sceneName;

    if (renderer) {
      renderer.forceContextLoss();
      renderer.context = null;
      renderer = null;
      renderer.domElement.removeEventListener("mousedown", onMouseDown, false);
      renderer.domElement.removeEventListener("mousemove", startRender, false);
      renderer.domElement.removeEventListener("wheel", startRender, false);
      window.removeEventListener("resize", onWindowResize, false);
    }

    renderer = new WebGLRenderer({
      antialias: true,
      logarithmicDepthBuffer: true
    });

    renderer.setSize(canvasWidth, canvasHeight);
    renderer.setClearColor(0x000000, 1);
    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = PCFSoftShadowMap;
    document.getElementById(containerId).appendChild(renderer.domElement);

    let camera = new OrthographicCamera(
      canvasWidth / -frustumSize,
      canvasWidth / frustumSize,
      canvasHeight / frustumSize,
      canvasHeight / -frustumSize,
      1,
      1000
    );

    camera.name = "camera";
    camera.updateProjectionMatrix();
    scene3d.add(camera);
    scenes[sceneName] = scene3d;

    // new THREE.TrackballControls
    controls = new TrackballControls(camera, renderer.domElement);
    controls.rotateSpeed = 3.0;
    controls.zoomSpeed = 0.5;
    controls.panSpeed = 2.0;
    controls.noZoom = false;
    controls.noPan = false;
    controls.staticMoving = false;
    controls.dynamicDampingFactor = 0.3;
    controls.cylindricalRotation = true;
    controls.keys = [null, 17, 16]; // [rotate[no button], zoom[cmd], pan[shift]

    //new THREE.AmbientLight (soft white light)
    let light = new AmbientLight(0x404040);
    light.name = "ambientLight";
    scene3d.add(light);

    //new THREE.PointLight (white light)
    let pointLight = new PointLight(0xffffff, 0.8, 0);
    camera.add(pointLight);

    renderer.domElement.addEventListener("mousedown", onMouseDown, false);
    renderer.domElement.addEventListener("wheel", startRender, false);
    window.addEventListener("resize", onWindowResize, false);

    store.dispatch("viewer/setViewerStatus", true);
    render();
  }

  function animate() {
    requestId = window.requestAnimationFrame(animate);
    controls.update();
    render();
  }

  function render() {
    if (store.state.viewer.isRendering === false) {
      return;
    }
    let camera = scene3d.getObjectByName("camera");
    renderer.render(scene3d, camera);
    store.dispatch("viewer/setIsRendering", false);
  }
};

// ===================
// Delete 3D scene ===
// ===================
export const removeRenderingScene = function(sceneName) {
  var sceneToClean = sceneName instanceof Scene ? sceneName : scenes[sceneName];
  if (sceneToClean) {
    var elementsInTheScene = sceneToClean.children.length;
    for (var i = elementsInTheScene - 1; i >= 0; i--) {
      var mesh = sceneToClean.children[i];
      if (mesh instanceof Mesh) {
        mesh.geometry.dispose();
        mesh.material.dispose();
      }
      sceneToClean.remove(mesh);
    }
    renderer.renderLists.dispose();
    if (requestId) {
      window.cancelAnimationFrame(requestId);
      renderer.forceContextLoss();
      requestId = null;
      controls = null;
      renderer = null;
    }
    scenes[sceneName] = null;
    store.dispatch("viewer/setViewerStatus", false);
    window.removeEventListener("resize", onWindowResize, false);
  }
};

// ==================================
// Import a 3D model file from db ===
// ==================================
export const import3DModel = function(options, callback) {
  let caseId = store.state.caseId;
  let morphologyId = store.state.morphologyId;
  store.dispatch("viewer/setLoading", true);
  console.time("...import3DModel");
  let meshName =
    options && options.meshName ? options.meshName : "model_" + caseId;
  if (meshName == "model_" + caseId) {
    verify_and_refresh(function() {
      request
        .get("/api/case/" + caseId + "/")
        .set("Authorization", "Bearer " + store.state.accessToken)
        .then(function(resp) {
          let data = resp.body.surface_data;
          store.dispatch("viewer/setLoading", false);
          if (data.length > 0) {
            render3DModel(data, meshName, options, callback);
          } else {
            callback(resp);
          }
        })
        .catch(error => {
          console.log(error);
          store.dispatch("viewer/setLoading", false);
          callback(error.response);
        });
    });
  } else {
    verify_and_refresh(function() {
      request
        .get("/api/morphology/" + morphologyId + "/")
        .set("Authorization", "Bearer " + store.state.accessToken)
        .then(function(resp) {
          let data = resp.body.convexHull_data;
          store.dispatch("viewer/setLoading", false);
          if (data.length > 0) {
            render3DModel(data, meshName, options, callback);
          } else {
            callback(resp);
          }
        })
        .catch(error => {
          console.log(error);
          store.dispatch("viewer/setLoading", false);
          callback(error.response);
        });
    });
  }
};

// =====================
// Render a 3D model ===
// =====================
export const render3DModel = function(dataArray, meshName, options, callback) {
  console.time("...render3DModel");
  let data = Float32Array.from(dataArray);
  let geometry = new BufferGeometry();
  geometry.setAttribute("position", new BufferAttribute(data, 3));
  geometry.computeVertexNormals();
  geometry.normalizeNormals();
  if (scenes["r3D"].getObjectByName(meshName)) {
    updateGeometry(meshName, geometry);
  } else {
    geometry.attributes.position.needsUpdate = true;
    let material = new MeshPhongMaterial({
      color: options && options.color ? options.color : 0xf5f5f5,
      shininess: 60,
      specular: 0x111111,
      emissive: 0x0,
      opacity: options && options.opacity ? options.opacity : 1.0,
      transparent: options && options.opacity != undefined ? true : false,
      visible: options && options.visible != undefined ? options.visible : true,
      side: DoubleSide
    });
    let mesh = new Mesh(geometry, material);
    mesh.name = meshName;
    scenes["r3D"].add(mesh);
    if (meshName == "model_" + store.state.caseId) {
      centerCameraOnObject(scenes["r3D"], mesh);
    }
  }
  console.timeEnd("...render3DModel");
  store.dispatch("viewer/setLoading", false);
  if (callback) {
    callback();
  }
};

// ======================================
// Change opacity level on 3D model =====
// ======================================
export const changeOpacity = function(meshName, opacityValue) {
  let mesh = scenes["r3D"].getObjectByName(meshName);
  if (mesh) {
    mesh.material.opacity = opacityValue;
    mesh.material.transparent = opacityValue === 1.0 ? false : true;
  }
};

// ====================
// Show/Hide a mesh ===
// ====================
export const toggleSurface = function(meshName) {
  var mesh = scenes["r3D"].getObjectByName(meshName);
  mesh.visible = !mesh.visible;
  store.dispatch("viewer/setIsRendering", true);
};

// =================================
// Enable picking of mesh name =====
// =================================
export const activatePicking = function(pickType, meshName) {
  var ray = new Raycaster();
  var camera = scenes["r3D"].getObjectByName("camera");
  var isMoved = false;
  var last_intersects = undefined;
  var mesh = scenes["r3D"].getObjectByName(meshName);

  var onDocumentMouseMove = function() {
    isMoved = true;
  };

  var onDocumentMouseDown = function(event) {
    isMoved = false;
    let rect = renderer.domElement.getBoundingClientRect();
    let x = (event.clientX - rect.left) / rect.width;
    let y = (event.clientY - rect.top) / rect.height;

    if (camera.type == "OrthographicCamera") {
      let dir = new Vector3();
      let vector = new Vector3();
      vector.set(x * 2 - 1, -y * 2 + 1, -1); // z = - 1 important!
      vector.unproject(camera);
      dir.set(0, 0, -1).transformDirection(camera.matrixWorld);
      ray.set(vector, dir);
    } else {
      let vector = new Vector3(x * 2 - 1, -y * 2 + 1, 0.5);
      vector.unproject(camera);
      ray.set(camera.position, vector.sub(camera.position).normalize());
    }

    mesh.geometry.computeBoundingBox();
    let intersects = ray.ray.intersectBox(mesh.geometry.boundingBox);

    last_intersects = intersects;
    renderer.domElement.onmousemove = onDocumentMouseMove;
  };

  var onDocumentMouseClick = function(event) {
    if (isMoved) {
      return;
    }
    store.dispatch("viewer/setIsRendering", true);

    let rect = renderer.domElement.getBoundingClientRect();
    let x = (event.clientX - rect.left) / rect.width;
    let y = (event.clientY - rect.top) / rect.height;
    if (camera.type == "OrthographicCamera") {
      let dir = new Vector3();
      let vector = new Vector3();
      vector.set(x * 2 - 1, -y * 2 + 1, -1); // z = - 1 important!
      vector.unproject(camera);
      dir.set(0, 0, -1).transformDirection(camera.matrixWorld);
      ray.set(vector, dir);
    } else {
      let vector = new Vector3(x * 2 - 1, -y * 2 + 1, 0.5);
      vector.unproject(camera);
      ray.set(camera.position, vector.sub(camera.position).normalize());
    }

    let intersects = ray.intersectObject(mesh);
    if (intersects.length > 0 && last_intersects) {
      if (has(pickingFunctions, pickType)) {
        pickingFunctions[pickType](meshName, intersects);
      }
      last_intersects = undefined;
    }
  };
  renderer.domElement.onclick = onDocumentMouseClick;
  renderer.domElement.onmousedown = onDocumentMouseDown;
};

// =================================
// Disable picking of mesh name ====
// =================================
export const deactivatePicking = function() {
  renderer.domElement.onclick = null;
  renderer.domElement.onmousedown = null;
};

// ====================
// Show/Hide a mesh ===
// ====================
export const togglePickCenter = function() {
  let meshName = "model_" + store.state.caseId;
  var mesh = scenes["r3D"].getObjectByName(meshName);

  var ray = new Raycaster();
  var camera = scenes["r3D"].getObjectByName("camera");
  let controls = getRenderingControls();

  // onDocumentMouseClick get the new center of rotation and set it
  var onDocumentMouseDownPickCenter = function onDocumentMouseDown(event) {
    let vector = new Vector3();
    let rect = renderer.domElement.getBoundingClientRect();
    let x = (event.clientX - rect.left) / rect.width;
    let y = (event.clientY - rect.top) / rect.height;

    if (camera.type == "OrthographicCamera") {
      let dir = new Vector3();
      vector.set(x * 2 - 1, -y * 2 + 1, -1); // z = - 1 important!
      vector.unproject(camera);
      dir.set(0, 0, -1).transformDirection(camera.matrixWorld);
      ray.set(vector, dir);
    } else {
      vector = new Vector3(x * 2 - 1, -y * 2 + 1, 0.5);
      vector.unproject(camera);
      ray.set(camera.position, vector.sub(camera.position).normalize());
    }
    let intersects = ray.intersectObject(mesh);
    if (intersects.length > 0) {
      store.dispatch("viewer/setCustomRotationCenter", intersects[0].point);
      setCentroid(controls, intersects[0].point);
    }
    store.dispatch("viewer/setIsRendering", true);
    renderer.domElement.onclick = null;
  };
  renderer.domElement.onclick = onDocumentMouseDownPickCenter;
};

// ===========================================
// Picking function for centerline seeds =====
// ===========================================
function centerlineSeeds(meshName, intersects) {
  let mesh = scenes["r3D"].getObjectByName(meshName);
  let radius = mesh.geometry.boundingSphere.radius * 0.025;
  let coordinates = {
    x: intersects[0].point.x,
    y: intersects[0].point.y,
    z: intersects[0].point.z
  };
  let numberOfSeeds = store.state.centerline.seeds.length;
  let color = numberOfSeeds == 0 ? 0xffff00 : 0x0000ff;
  let sphereName = "centerline_seed_" + numberOfSeeds;
  renderSphere("r3D", coordinates, radius, color, sphereName);
  store.dispatch("centerline/addSeed", coordinates);
}

// ==============================
// Show/Hide Centerline seeds ===
// ==============================
export const toggleCenterlineSeeds = function() {
  let numberOfSeeds = store.state.centerline.seeds.length;
  for (let i = 0; i < numberOfSeeds; i++) {
    let sphereName = "centerline_seed_" + i;
    toggleSurface(sphereName);
  }
};

// ==============================
// Show/Hide Centerline seeds ===
// ==============================
export const toggleAneurysmSeed = function() {
  let sphereName = "aneurysm_seed";
  toggleSurface(sphereName);
};

// ====================================
// Load and Render Centerline seeds ===
// ====================================
export const loadCenterlineSeeds = function() {
  let mesh = scenes["r3D"].getObjectByName("model_" + store.state.caseId);
  let numberOfSeeds = store.state.centerline.seeds.length;
  for (let i = 0; i < numberOfSeeds; i++) {
    let coordinates = store.state.centerline.seeds[i];
    let radius = mesh.geometry.boundingSphere.radius * 0.025;
    let color = i == 0 ? 0xffff00 : 0x0000ff;
    let sphereName = "centerline_seed_" + i;
    renderSphere("r3D", coordinates, radius, color, sphereName);
  }
};

// ====================================
// Load and Render Centerline seeds ===
// ====================================
export const loadAneurysmSeed = function() {
  let mesh = scenes["r3D"].getObjectByName("model_" + store.state.caseId);
  let radius = mesh.geometry.boundingSphere.radius * 0.01;
  let coordinates = store.state.morphology.aneurysm_seed;
  renderSphere("r3D", coordinates, radius, 0xff0000, "aneurysm_seed");
};

// ========================================
// Picking function for aneurysm seed =====
// ========================================
function aneurysmSeed(meshName, intersects) {
  let mesh = scenes["r3D"].getObjectByName(meshName);
  let radius = mesh.geometry.boundingSphere.radius * 0.01;
  let coordinates = {
    x: intersects[0].point.x,
    y: intersects[0].point.y,
    z: intersects[0].point.z
  };
  if (store.state.morphology.aneurysm_seed) {
    scenes["r3D"].remove(scenes["r3D"].getObjectByName("aneurysm_seed"));
  }
  renderSphere("r3D", coordinates, radius, 0xff0000, "aneurysm_seed");
  store.dispatch("morphology/setAneurysmSeed", coordinates);
  updateAneurysmSeed(coordinates);
}

// ======================================================
// Picking function for plane definition using a line ===
// ======================================================
function planeDefinition(meshName, intersects) {
  let points = [];
  if (intersects.length == 0) {
    return;
  } else {
    points.push(intersects[0].point);
  }

  var ray = new Raycaster();
  var camera = scenes["r3D"].getObjectByName("camera");
  var mesh = scenes["r3D"].getObjectByName(meshName);

  let onDocumentMouseUp = function() {
    renderer.domElement.onmousemove = null;
    renderer.domElement.onmouseup = null;
    let origin = new Vector3();
    origin.lerpVectors(points[0], points[1], 0.5);
    let distance = new Vector3();
    distance.set(
      points[1].x - points[0].x,
      points[1].y - points[0].y,
      points[1].z - points[0].z
    );
    let normal = new Vector3();
    let direction = camera.getWorldDirection();
    normal.crossVectors(direction, distance);
    normal.normalize();

    store.dispatch("morphology/setPlaneDistance", distance);
    store.dispatch("morphology/setPlaneOrigin", origin);
    store.dispatch("morphology/setPlaneNormal", normal);
    updateCuttingPlane(origin, normal, distance);

    deactivatePlane();
    renderCutPlane();
  };

  let onDocumentMouseMove = function(event) {
    let rect = renderer.domElement.getBoundingClientRect();
    let x = (event.clientX - rect.left) / rect.width;
    let y = (event.clientY - rect.top) / rect.height;

    if (camera.type == "OrthographicCamera") {
      let dir = new Vector3();
      let vector = new Vector3();
      vector.set(x * 2 - 1, -y * 2 + 1, -1); // z = - 1 important!
      vector.unproject(camera);
      dir.set(0, 0, -1).transformDirection(camera.matrixWorld);
      ray.set(vector, dir);
    } else {
      let vector = new Vector3(x * 2 - 1, -y * 2 + 1, 0.5);
      vector.unproject(camera);
      ray.set(camera.position, vector.sub(camera.position).normalize());
    }

    let intersects = ray.intersectObject(mesh);

    if (intersects.length == 0) {
      return;
    } else {
      points[1] = intersects[0].point;
    }
    let geometry = new BufferGeometry().setFromPoints(points);
    if (scenes["r3D"].getObjectByName("cutLine")) {
      updateGeometry("cutLine", geometry);
    } else {
      let material = new LineBasicMaterial({
        color: 0xff0000,
        linewidth: 2,
        depthTest: false
      });
      let line = new Line(geometry, material);
      line.name = "cutLine";
      scenes["r3D"].add(line);
    }
  };

  renderer.domElement.onmousemove = onDocumentMouseMove;
  renderer.domElement.onmouseup = onDocumentMouseUp;
}

// ============================================
// Render cut plane and intersection points ===
// ============================================
export const renderCutPlane = function() {
  let mesh = scenes["r3D"].getObjectByName("model_" + store.state.caseId);
  let origin = store.state.morphology.planeOrigin;
  let normal = store.state.morphology.planeNormal;
  let distance = store.state.morphology.planeDistance;
  var plane = new Plane();
  plane.setFromNormalAndCoplanarPoint(normal, origin);

  var intersection = new Float32Array(
    mesh.geometry.attributes.position.array.length / 12
  );
  intersect.prepare(mesh);
  intersection = intersect.find(mesh, plane, intersection);

  // limit to pts inside the planes defined by picked pts
  distance.multiplyScalar(0.5);
  let a_origin = new Vector3().addVectors(origin, distance.clone().negate());
  let a_normal = distance.clone().normalize();
  let b_origin = new Vector3().addVectors(origin, distance.clone());
  let b_normal = distance
    .clone()
    .normalize()
    .negate();
  let rightPlane = new Plane().setFromNormalAndCoplanarPoint(
    a_normal,
    a_origin
  );
  let leftPlane = new Plane().setFromNormalAndCoplanarPoint(b_normal, b_origin);

  // HELPERS FOR DEBUG (TODO move to proper lib)

  // var arrowHelper1 = new ArrowHelper(leftNormal, leftOrigin, 5, "green");
  // scenes["r3D"].add(arrowHelper1);
  // var arrowHelper2 = new ArrowHelper(rightNormal, rightOrigin, 5, "purple");
  // scenes["r3D"].add(arrowHelper2);

  // var helper1 = new PlaneHelper(leftPlane, 100, 0xff0000);
  // var helper2 = new PlaneHelper(rightPlane, 100, 0xffff00);
  // scenes["r3D"].add(helper1);
  // scenes["r3D"].add(helper2);

  let neck_intersection = limitIntersection(
    intersection,
    [leftPlane, rightPlane],
    true,
    scenes["r3D"]
  );

  let geometry_intersection = new BufferGeometry();
  geometry_intersection.setAttribute(
    "position",
    new Float32BufferAttribute(neck_intersection, 3)
  );
  var material_intersection = new PointsMaterial({ color: 0xff0000, size: 5 });

  if (scenes["r3D"].getObjectByName("cutPoints")) {
    scenes["r3D"].getObjectByName("cutPoints").geometry.dispose();
    scenes["r3D"].remove(scenes["r3D"].getObjectByName("cutPoints"));
  }
  let points = new Points(geometry_intersection, material_intersection);
  points.name = "cutPoints";
  points.visible = true;
  scenes["r3D"].add(points);

  scenes["r3D"].remove(scenes["r3D"].getObjectByName("cutLine"));
  controls.enabled = true;
  store.dispatch("viewer/setIsRendering", true);

  let planeGeometry = getCuttingPlaneGeometry(neck_intersection, scenes["r3D"]);
  let planeMaterial = new MeshBasicMaterial({
    color: "green",
    side: DoubleSide,
    transparent: true,
    opacity: 0.6
  });

  if (scenes["r3D"].getObjectByName("cuttingPlane")) {
    scenes["r3D"].getObjectByName("cuttingPlane").geometry.dispose();
    scenes["r3D"].remove(scenes["r3D"].getObjectByName("cuttingPlane"));
  }

  let cuttingPlane = new Mesh(planeGeometry, planeMaterial);
  cuttingPlane.name = "cuttingPlane";
  scenes["r3D"].add(cuttingPlane);
};

// ====================================
// Render a line between two points ===
// ====================================
export const renderLine = function(srcPoint, targetPoint, meshName, options) {
  let positions = [];
  positions.push(srcPoint[0], srcPoint[1], srcPoint[2]);
  positions.push(targetPoint[0], targetPoint[1], targetPoint[2]);
  let geometry = new LineGeometry();
  geometry.setPositions(positions);

  if (scenes["r3D"].getObjectByName(meshName)) {
    updateGeometry(meshName, geometry);
  } else {
    let material = new LineMaterial({
      color: options.color ? options.color : 0xff0000,
      linewidth: 0.005,
      depthTest: options.depthTest ? options.depthTest : false,
      dashed: false
    });
    let line = new Line2(geometry, material);
    line.computeLineDistances();
    line.scale.set(1, 1, 1);
    line.name = meshName;
    scenes["r3D"].add(line);
  }
};

// ===========================================
// Activate plane drag and drop definition ===
// ===========================================
export const activatePlane = function() {
  // disable default picking
  deactivatePicking();
  // deactivate 3D controls
  controls.enabled = false;
  // render a picking plane
  let camera = scenes["r3D"].getObjectByName("camera");
  let model = scenes["r3D"].getObjectByName("model_" + store.state.caseId);
  let center = getGeometryCentroid(model.geometry).center;
  var planeGeometry = new PlaneGeometry(200, 200);
  // align perpendicular to camera
  planeGeometry.applyMatrix4(camera.matrix);
  var matrixT = new Matrix4().makeTranslation(
    center.x - camera.position.x,
    center.y - camera.position.y,
    center.z - camera.position.z
  );
  planeGeometry.applyMatrix4(matrixT);

  var material = new MeshLambertMaterial({
    color: "yellow",
    transparent: true,
    opacity: 0.7,
    side: DoubleSide,
    visible: false
  });
  var mesh = new Mesh(planeGeometry, material);
  mesh.name = "ghostPlane";
  scenes["r3D"].add(mesh);
  store.dispatch("viewer/setIsRendering", true);
  activatePicking("planeDefinition", "ghostPlane");
};

// =============================================
// Deactivate plane drag and drop definition ===
// =============================================
export const deactivatePlane = function() {
  // remove ghost plane
  scenes["r3D"].remove(scenes["r3D"].getObjectByName("ghostPlane"));
  store.dispatch("viewer/setPlanePicking", false);
  // deactivate picking
  deactivatePicking();
  controls.enabled = true;
  store.dispatch("viewer/setIsRendering", true);
};

// ==================================================
// Remove plane line definition from 3D and store ===
// ==================================================
export const removePlane = function() {
  controls.enabled = true;
  scenes["r3D"].remove(scenes["r3D"].getObjectByName("ghostPlane"));
  scenes["r3D"].remove(scenes["r3D"].getObjectByName("cuttingPlane"));
  scenes["r3D"].remove(scenes["r3D"].getObjectByName("cutPoints"));
  store.dispatch("morphology/setPlaneDistance", null);
  store.dispatch("morphology/setPlaneOrigin", null);
  store.dispatch("morphology/setPlaneNormal", null);
  store.dispatch("viewer/setPlanePicking", false);
  store.dispatch("viewer/setIsRendering", true);
};

//==============================================
// Render a mesh sphere object with some props ==
// ==============================================
function renderSphere(sceneName, coordinates, radius, color, meshName, props) {
  let scene = getRenderingScene(sceneName);
  let mesh = scene.getObjectByName(meshName);
  if (mesh) {
    scene.remove(mesh);
  }
  let geometry = new SphereGeometry(radius, 32, 32);
  let material = new MeshPhongMaterial({
    color: color,
    shininess: 60,
    specular: 0x111111,
    emissive: 0x0,
    side: DoubleSide
  });
  let sphere = new Mesh(geometry, material);
  sphere.position.set(coordinates.x, coordinates.y, coordinates.z);
  sphere.name = meshName;
  if (props) {
    each(props, function(v, k) {
      sphere[k] = v;
    });
  }
  scene.add(sphere);
  store.dispatch("viewer/setIsRendering", true);
}

// ===============================================
// Update the geometry of a existing mesh ========
// ===============================================
function updateGeometry(meshName, geometry) {
  let mesh = scenes["r3D"].getObjectByName(meshName);
  mesh.geometry.dispose();
  mesh.geometry = geometry.clone();
  mesh.geometry.attributes.position.needsUpdate = true;
  store.dispatch("viewer/setIsRendering", true);
}

// ============================================
// Center camera on object ====================
// ============================================
function centerCameraOnObject(sceneName, threeObj) {
  let scene3d =
    sceneName instanceof Scene ? sceneName : getRenderingScene(sceneName);
  if (!(threeObj instanceof Mesh)) {
    threeObj = scene3d.getObjectByName(threeObj);
  }

  if (threeObj) {
    store.dispatch("viewer/setIsRendering", true);

    let boundingBox = getGeometryCentroid([threeObj.geometry]);
    let camera = scene3d.getObjectByName("camera");

    camera.lookAt(boundingBox.center);

    let controls = getRenderingControls();
    setCentroid(controls, boundingBox.center);

    camera.position.x = boundingBox.center.x;
    camera.position.y = boundingBox.center.y;
    camera.position.z = boundingBox.max.z + 50;

    let cameraPosition = cloneDeep(camera.position);
    store.dispatch("viewer/setCameraPosition", cameraPosition);
  }
}

// ============================================
// Set centroId to controls ===================
// ============================================
function setCentroid(controls, centroid) {
  if (controls) {
    controls.target.copy(centroid);
    controls.target0.copy(centroid);
  }
  return controls;
}

// ============================================
// Restore centroId to controls ===============
// ============================================
function restoreCentroId(controls, sceneName, mesh) {
  let scene3d =
    sceneName instanceof Scene ? sceneName : getRenderingScene(sceneName);
  if (!(mesh instanceof Mesh)) {
    mesh = scene3d.getObjectByName(mesh);
  }
  if (mesh) {
    store.dispatch("viewer/setIsRendering", true);
    let boundingBox = getGeometryCentroid([mesh.geometry]);
    controls.target.copy(boundingBox.center);
    controls.target0.copy(boundingBox.center);
  }
}

// =================================
// Remove last centerline seed =====
// =================================
export const removeLastCenterlineSeed = function() {
  let numberOfSeeds = store.state.centerline.seeds.length;
  let seedName = "centerline_seed_" + (numberOfSeeds - 1);
  scenes["r3D"].remove(scenes["r3D"].getObjectByName(seedName));
  store.dispatch("viewer/setIsRendering", true);
  store.dispatch("centerline/removeLastSeed");
};

// =================================
// Remove all centerline seeds =====
// =================================
export const removeCenterlineSeeds = function() {
  let numberOfSeeds = store.state.centerline.seeds.length;
  for (let i = 0; i < numberOfSeeds; i++) {
    let seedName = "centerline_seed_" + i;
    scenes["r3D"].remove(scenes["r3D"].getObjectByName(seedName));
  }
  store.dispatch("viewer/setIsRendering", true);
  store.dispatch("centerline/removeSeeds");
};

// ==========================
// Remove aneurysm seed =====
// ==========================
export const removeAneurysmSeed = function() {
  scenes["r3D"].remove(scenes["r3D"].getObjectByName("aneurysm_seed"));
  store.dispatch("viewer/setIsRendering", true);
  store.dispatch("morphology/removeAneurysmSeed");
};

// ======================================
// Reset the camera to default values ===
// ======================================
export const resetCamera = function() {
  store.dispatch("viewer/setIsRendering", true);
  let cameraPosition = store.state.viewer.cameraPosition;
  let mesh = scenes["r3D"].getObjectByName("model_" + store.state.caseId);
  restoreCentroId(controls, "r3D", mesh);
  let boundingBox = getGeometryCentroid([mesh.geometry]);
  let camera = scenes["r3D"].getObjectByName("camera");
  camera.lookAt(boundingBox.center);
  camera.position.x = cameraPosition.x;
  camera.position.y = cameraPosition.y;
  camera.position.z = cameraPosition.z;
  camera.updateProjectionMatrix();
};

// =================================================
// Get rendering scene, controls from scene name ===
// =================================================
export const getRenderingScene = function(sceneName) {
  return scenes[sceneName] ? scenes[sceneName] : null;
};

export const getRenderingControls = function() {
  return controls;
};
