import { find } from "lodash";

function initialState() {
  return {
    chartType: "",
    xAxisKeys: [],
    yAxisKeys: [],
    activeKeyX: null,
    activeKeyY: null,
    loading: false,
    showLiteratureData: false,
    currentSeries: []
  };
}

const state = {
  ...initialState()
};

const mutations = {
  reset(state) {
    // acquire initial state
    const s = initialState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  setLoading(state, value) {
    state.loading = value;
  },
  setChartType(state, value) {
    state.chartType = value;
  },
  setxAxisKeys(state, value) {
    state.xAxisKeys = value;
  },
  setyAxisKeys(state, value) {
    state.yAxisKeys = value;
  },
  setActiveKey_x(state, value) {
    state.activeKeyX = value;
  },
  setActiveKey_y(state, value) {
    state.activeKeyY = value;
  },
  setLiteratureData(state, value) {
    state.showLiteratureData = value;
  },
  setSerieColor(state, value) {
    let serie = find(state.currentSeries, ["key", value.key]);
    serie.marker.color = value.color;
  },
  setSerieSize(state, value) {
    let serie = find(state.currentSeries, ["key", value.key]);
    serie.marker.size = value.size;
  },
  setCurrentSeries(state, value) {
    state.currentSeries = value;
  }
};

const actions = {
  clearState({ commit }) {
    commit("reset");
  },
  setLoading({ commit }, value) {
    commit("setLoading", value);
  },
  setChartType({ commit }, value) {
    commit("setChartType", value);
  },
  setxAxisKeys({ commit }, value) {
    commit("setxAxisKeys", value);
  },
  setyAxisKeys({ commit }, value) {
    commit("setyAxisKeys", value);
  },
  setActiveKey_x({ commit }, value) {
    commit("setActiveKey_x", value);
  },
  setActiveKey_y({ commit }, value) {
    commit("setActiveKey_y", value);
  },
  setLiteratureData({ commit }, value) {
    commit("setLiteratureData", value);
  },
  setSerieColor({ commit }, value) {
    commit("setSerieColor", value);
  },
  setSerieSize({ commit }, value) {
    commit("setSerieSize", value);
  },
  setCurrentSeries({ commit }, value) {
    commit("setCurrentSeries", value);
  }
};

const getters = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
